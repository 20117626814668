<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="invoice_dt"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    show-select
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Ажилтан</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>


        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              нэмэх
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-combobox
                      v-model="editedItem.notes"
                      label="Даатгуулагчийн төрөл *"
                      :items="insrType"
                      item-text="name"
                      item-value="code"
                      :rules="[required]"
                      outlined
                      dense
                      return-object
                      :hint="`${sInsrType}`"
                      persistent-hint
                      background-color="pink lighten-5"
                      v-on:input="changeInsrType"
                    >
                    </v-combobox>
                  </v-col> 
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.reg_num"
                      label="Татвар төлөгчийн дугаар *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.lastname"
                      :rules="[required]"
                      label="Овог *"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.firstname"
                      :rules="[required]"
                      label="Нэр *"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.position"
                      label="Албан тушаал"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.salary"
                      label="Үндсэн цалин"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.socl_insr_bk_no"
                      label="НДД-н дугаар"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.hlth_insr_bk_no"
                      label="ЭМДД-н дугаар"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="red lighten-2"
            dark
            class="mb-2 ml-2"
            v-if="!readonly_check"
            @click="deleteItem"
          >
            Устгах
            <v-icon small class="ml-1">mdi-delete</v-icon>
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон ажилтаны мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8">
          <v-icon small>mdi-filter-variant</v-icon>
				</th>
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="!readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:item.salary="{ item }">
      <span>{{formatPrice(item.salary)}}</span>
    </template>
    <template v-slot:item.notes="{ item }">
      <span>{{getName(item.notes)}}</span>
    </template>
    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  
  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      insrType: [],
      taxinfos: [],
      sInsrType: '',
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode
        },
        { text: 'Татвар төлөгчийн дугаар', value: 'reg_num', divider: true, class: bgColorCode },
        { text: 'Даатгуулагчийн төрөл', value: 'notes', divider: true, class: bgColorCode },
        { text: 'Овог', value: 'lastname', divider: true, class: bgColorCode },
        { text: 'Нэр', value: 'firstname', divider: true, class: bgColorCode },
        { text: 'Албан тушаал', value: 'position', divider: true, class: bgColorCode },
        { text: 'Үндсэн цалин', value: 'salary', divider: true, class: bgColorCode },
        { text: 'НДД-н дугаар', value: 'socl_insr_bk_no', divider: true, class: bgColorCode },
        { text: 'ЭМДД- дугаар', value: 'hlth_insr_bk_no', divider: true, class: bgColorCode },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode },
      ],
      filters: {
        reg_num: [],
        notes: [],
        lastname: [],
				firstname: [],
				position: [],
				salary: [],
        socl_insr_bk_no: [],
				hlth_insr_bk_no: [],
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        reg_num: '',
        // notes: {code: '501001', name: '01 Аж ахуйн нэгж, байгууллагын ажилтан (ндш 11.5 ба 12.5)'},
        notes: '',
        lastname: '',
        firstname: '',
        position: '',
        salary: '',
        socl_insr_bk_no: '',
        hlth_insr_bk_no: '',
      },
      defaultItem: {
        reg_num: '',
        // notes: {code: '501001', name: '01 Аж ахуйн нэгж, байгууллагын ажилтан (ндш 11.5 ба 12.5)'},
        notes: '',
        lastname: '',
        firstname: '',
        position: '',
        salary: '',
        socl_insr_bk_no: '',
        hlth_insr_bk_no: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let year = user_dt[0].substr(0, 4)
        const params = {
          org_id: { toJSON: () => user_org.id },
        };
        axios.get('employers', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          })
          
        axios.get('commoncode/parent_code/501/'+year)
          .then(response => {
            this.insrType = response.data;
          })
          .catch(error => {
            console.log(error)
          })

        axios.get('taxinfos')
          .then(response => {
            this.taxinfos = response.data;
          })
          .catch(error => {
            console.log(error)
          })

      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      formatPrice(value) {
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      columnValueList(val) {
				// return this.gridRows.map((d) => d[val]);
        return this.gridRows.map((d) => d[val] ? d[val] : '-');
			},

      changeInsrType() {
        if (!this.editedItem.notes) { return }
        let tax = this.taxinfos.filter(x=> x.notes === this.editedItem.notes.code)[0]
        // alert(JSON.stringify(tax))
        this.sInsrType = tax ? 'НДШ: '+tax.socl_insr_rate+', НДШ(аан): '+tax.org_socl_insr_rate+', ХХОАТ: '+tax.psnl_inco_tax_rate : ''
      },

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.notes = this.insrType.filter(x=> x.code === this.editedItem.notes)[0]
        // alert(JSON.stringify(this.editedItem.notes))
        this.changeInsrType()
        this.dialog = true
      },

      getName(notes) {
        // return notes ? notes+':'+this.insrType.filter(x=> x.code === notes)[0].name : '-'
        return notes && this.insrType.filter(x=> x.code === notes).length > 0 ? this.insrType.filter(x=> x.code === notes)[0].name : '-'
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        this.selected.forEach(function (e) {
            ids.push(e.id);
        });

        axios.delete('employer/' + ids)
          .then(response => {
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.reloadMain()
            this.closeDelete()
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.editedItem.notes = this.editedItem.notes.code;
        if (this.editedIndex > -1) {
          axios.put('employer/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай засагдлаа.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Мэдээлэл засварлахад алдаа гарлаа.');
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          axios.post('employer/store', this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай орууллаа.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Мэдээлэл оруулахад алдаа гарлаа.');
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        this.close()
      },
      
      reloadMain() {
        this.initialize();
      },

    },
  }
</script>