<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="code"
    group-by="parent_code"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Нийтлэг код</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <!-- <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Гүйлгээ нэмэх
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.acct_name"
                      label="Гүйлгээний төрөл"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.acct_name"
                      label="Баримтын дугаар"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.acct_code"
                      label="Огноо"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.debit_balance"
                      label="Гүйлгээний утга"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.credit_balance"
                      label="Харилцагч"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.debit_txn_amnt"
                      label="Дебит данс"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.debit_txn_amnt"
                      label="Кредит данс"
                    ></v-text-field>
                  </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.debit_txn_amnt"
                      label="Дебит мөнгөн дүн"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.debit_txn_amnt"
                      label="Кредит мөнгөн дүн"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

      </v-toolbar>
    </template>

    <!-- <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th>
          <v-icon>mdi-filter-variant</v-icon>
				</th>
        <th v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon>mdi-filter-variant</v-icon>
          </div>
        </th><th>
          
        </th>
      </tr>
    </template> -->
 
    <template v-slot:item.actions="{ item }">
      <!-- <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>
    <!-- <template v-slot:group = "data">
      <span class="font-weight-bold">
        {{ data }}
      </span>
    </template> -->
    <!-- <template slot="group" slot-scope="props">
      <span class="font-weight-bold">
        Group {{props.groupIndex + 1}} - {{props.groupName}}
      </span>
    </template> -->
    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  
  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Нэр', value: 'name', divider: true, class: bgColorCode },
        { text: 'Код', value: 'code', divider: true, class: bgColorCode },
        { text: 'Агуулга', value: 'notes', divider: true, class: bgColorCode },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, width: '1%' },
      ],
      filters: {
        name: [],
        notes: [],
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        notes: '',
      },
      defaultItem: {
        name: '',
        notes: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios.get('commoncodes')
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          })
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.gridRows.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          this.gridRows.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>