<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="invoice_dt"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    show-select
    @item-selected="checkSelected"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Ерөнхий журнал</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-switch
          v-model="isActive"
          label="Хаалтын бичилт"
          color="success"
          value="true"
          @click="sClick"
          hide-details
        ></v-switch>
        <!-- Save haalt (start) -->
        <v-dialog
          persistent
          no-click-animation
          v-model="dialogHaalt"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="green lighten-2"
            dark
            :disabled="!isActive"
            v-if="!readonly_check"
            class="mb-2 mr-1 ml-1"
            @click="saveHaalt"
          >
            Хадгалах
          </v-btn>
          </template>
          <v-card>
            <v-card-title
              class="headline orange lighten-2"
              primary-title
            >
              Хаалтын бичилтийг хадгалах уу?
            </v-card-title>
            <v-card-text>
              Хаалтын гүйлгээг хадгалснаар гүйлгээ баланс болон тайлан дээр тухайн дүн бодогдон гарах боломжтой.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeHaalt"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="saveHaaltConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Save haalt (end) -->

        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Print (start) -->
        <v-btn
          color="warning lighten-2"
          dark
          class="mb-2 mr-1"
          @click="wholePrint()"
        >
          PDF <i class="mdi mdi-printer" aria-hidden="true"></i>
        </v-btn>
        <!-- Print (end) -->

        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows"
          :fields="export_fields"
          :labels="export_labels"
          name="journal.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-1">
            Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-bind="attrs"
              v-if="!readonly_check"
              v-on="on"
            >
              Гүйлгээ
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-combobox
                      v-model="editedItem.txn_type"
                      label="Гүйлгээний төрөл *"
                      :items="txntys"
                      item-text="name"
                      item-value="code"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                      :readonly="editedIndex === -1 ? false : true"
                    >
                    </v-combobox>
                  </v-col>                  
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.curr_rate"
                      label="Ханш"
                      outlined
                      dense
                      hide-details
                      v-if="editedItem.txn_type.code == '102003'"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.invoice_dt"
                          label="Огноо *"
                          append-icon="mdi-calendar"
                          :rules="[required, datecheck]"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          type="date-local"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.invoice_dt"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.cust_id"
                      label="Харилцагч"
                      :items="custs"
                      item-text="name"
                      outlined
                      dense
                      hide-details
                      return-object
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.txn_value"
                      label="Гүйлгээний утга"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.debit_acct_no"
                      label="Үндсэн данс *"
                      :items="accts"
                      item-text="comb_name"
                      item-value="notes"
                      :rules="[required]"
                      validate-on-blur
                      outlined
                      dense
                      return-object
                      hide-details
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.credit_acct_no"
                      label="Харьцсан данс *"
                      :items="accts"
                      item-text="comb_name"
                      item-value="notes"
                      :rules="[required]"
                      outlined
                      dense
                      cache-items
                      return-object
                      hide-details
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.debit_amnt"
                      label="Дебит дүн"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.credit_amnt"
                      label="Кредит дүн"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <!-- CSV file upload logic starts here -->
        <v-dialog
          v-model="csvDialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-2"
              dark
              class="mb-2 ml-1"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              Файл
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline blue lighten-2" primary-title>
              Excel файлаас мэдээлэл оруулах хэсэг
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mt-2">
                  <v-file-input
                    v-model="chosenFile"
                    label="Файл сонгох"
                    accept=".xls, .csv, .xlsx"
                    outlined
                    dense
                  >
                  </v-file-input>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="csvClose"
              >
                Цуцлах
              </v-btn>
              <v-btn
                :loading="loadXls"
                color="blue darken-1"
                text
                @click.prevent="csvSave"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- CSV file upload logic finishes here -->

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="red lighten-2"
            dark
            class="mb-2 ml-1"
            v-if="!readonly_check"
            @click="deleteItem"
          >
            Устгах
            <v-icon small class="ml-1">mdi-delete</v-icon>
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон гүйлгээний мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8">
          <v-icon small>mdi-filter-variant</v-icon>
				</th>
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        v-if="checkTxnType(item)"
        :disabled="readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>

    <template v-slot:item.debit_amnt="{ item }">
      <span>{{formatPrice(item.debit_amnt)}}</span>
    </template>
    <template v-slot:item.credit_amnt="{ item }">
      <span>{{formatPrice(item.credit_amnt)}}</span>
    </template>
    <!-- <template v-slot:item.cust_id="{ item }">
      <span>{{custName(item.cust_id)}}</span>
    </template> -->

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>


<script>
  import axios from 'axios';
  import XLSX from 'xlsx';
  import moment from 'moment';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'
  
  let bgColorCode = "orange lighten-5";

  export default {
    data: () => ({
      footerProps: {'items-per-page-options': [10, 20, 50, 100, 500, -1]},
      pagination: {
        sortBy: 'name'
      },
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      dialog: false,
      csvDialog: false,
      dialogDelete: false,
      dialogHaalt: false,
      search: '',
      selected: [],
      accts: [],
      txntys: [],
      custs: [],
      orgs: [],
      loadTable: true,
      csv: null,
      chosenFile: null,
      dataF: null,
      tableData:[],
      tableRow: [],
      loadXls: false,
      isActive: false,
      headers: [
        {
          text: 'Баримтын дугаар',
          align: 'start',
          sortable: false,
          value: 'invoice_no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Огноо', value: 'invoice_dt', divider: true, class: bgColorCode, width: '110px' },
        { text: 'Гүйлгээний утга', value: 'txn_value', divider: true, class: bgColorCode },
        { text: 'Харилцагч', value: 'cust_name', divider: true, class: bgColorCode },
        { text: 'Үндсэн данс', value: 'debit_acct_no', divider: true, class: bgColorCode, width: '1%' },
        { text: 'Харьцсан данс', value: 'credit_acct_no', divider: true, class: bgColorCode, width: '1%' },
        { text: 'Дебит дүн', value: 'debit_amnt', divider: true, class: bgColorCode, width: '120px', align: 'right' },
        { text: 'Кредит дүн', value: 'credit_amnt', divider: true, class: bgColorCode, width: '120px', align: 'right' },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, width: '1%', align: 'center' },
      ],
      export_fields: [
        'invoice_no', 'invoice_dt', 'txn_value', 'cust_name', 'debit_acct_no',
        'credit_acct_no', 'debit_amnt', 'credit_amnt'
      ],
      export_labels: {
        invoice_no: 'Баримтын дугаар',
        invoice_dt: 'Огноо',
        txn_value: 'Гүйлгээний утга',
        cust_name: 'Харилцагч',
        debit_acct_no: 'Үндсэн данс',
        credit_acct_no: 'Харьцсан данс',
        debit_amnt: 'Дебит дүн',
        credit_amnt: 'Кредит дүн'
      },

      csv_fields2: {
        invoice_no: 'No', invoice_dt: 'Date', txn_value: 'Value', cust_name: 'Customer',
        debit_acct_no: 'Debit Account', credit_acct_no: 'Credit Account',
        debit_amnt: 'Debit', credit_amnt: 'Credit'
      },
      csv_fields: [
        'invoiceNo', 'invoiceDt', 'txnValue', 'custName', 'debitAcctNo',
        'creditAcctNo', 'debitAmnt', 'creditAmnt'
      ],
      filters: {
        invoice_no: [],
        invoice_dt: [],
				txn_value: [],
				cust_name: [],
				debit_acct_no: [],
        credit_acct_no: [],
				debit_amnt: [],
				credit_amnt: [],
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        txn_type: {code: '102001', name: 'Энгийн гүйлгээ'},
        invoice_dt: '', //new Date().toISOString().substr(0, 10),
        txn_value: '',
        debit_acct_no: '',
        credit_acct_no: '',
        invoice_no: '1001',
        debit_amnt: '',
        credit_amnt: '',
        debit_amnt_usd: '',
        credit_amnt_usd: '',
        curr_rate: '2850',
        org_id: '',
      },
      defaultItem: {
        txn_type: {code: '102001', name: 'Энгийн гүйлгээ'},
        invoice_dt: '', //new Date().toISOString().substr(0, 10),
        txn_value: '',
        debit_acct_no: '',
        credit_acct_no: '',
        invoice_no: '1001',
        debit_amnt: '',
        credit_amnt: '',
        debit_amnt_usd: '',
        credit_amnt_usd: '',
        curr_rate: '2850',
        org_id: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						// return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
            // return (this.filters[f].length < 1 || this.filters[f].includes(d[f])) && this.isActive ? (d.txn_type == 102004) : (d.txn_type != 102004);
            return this.isActive ? (d.txn_type == 102004) && (this.filters[f].length < 1 || this.filters[f].includes(d[f])) : (d.txn_type != 102004) && (this.filters[f].length < 1 || this.filters[f].includes(d[f]));
					});
				});
			},
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      csvDialog (val) {
        val || this.csvClose()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogHaaltSave (val) {
        val || this.closeHaalt()
      },
    },

    created () {
      if (!sessionStorage.hasOwnProperty('user_org') || !sessionStorage.hasOwnProperty('user_dt')) {
        // alert('Аж ахуйн нэгж / Тайлант үе сонгогдоогүй байна.');
        this.loadTable = false;
        return false;
      }
      this.initialize()
      this.getCustInfo()
      this.getOrgInfo()
      this.getOptions()
    },

    methods: {
      initialize () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('journals', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
          })
          .catch(error => {
            this.loadTable = false;
            console.log(error)
          })
      },

      getHaalt () {
        this.loadTable = true;
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('journal/haalt', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
          })
          .catch(error => {
            this.loadTable = false;
            console.log(error)
          })
      },

      getGrid () {
        this.loadTable = true;
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('journals', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
          })
          .catch(error => {
            this.loadTable = false;
            console.log(error)
          })
      },

      isOrgSet() {
        let org = sessionStorage.getItem('user_org')
        return org ? true : false;
      },

      isDtSet() {
        return sessionStorage.getItem('user_dt') != null ? true : false;
      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      datecheck(value) {
        var date = Date.parse(value.toString());
        if (isNaN(date))
          return 'Буруу огноо байна.';
      },

      formatPrice(value) {
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      custName(value) {
        return this.custs.find(x => x.id === value).name;
      },

      getCustInfo () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('customer/org_id', { params })
          .then(response => {
            this.custs = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      getOptions () {
        axios.get('commoncode/accts_org_id/' + JSON.parse(sessionStorage.getItem('user_org')).id)
          .then(response => {
            this.accts = response.data;
          })
          .catch(error => {
            console.log(error)
          })

        axios.get('commoncode/parent_code/102')
          .then(response => {
            this.txntys = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      columnValueList(val) {
				// return this.gridRows.map((d) => d[val]);
        return this.gridRows.map((d) => d[val] ? d[val] : '-');
			},

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        let dAcct = this.accts.find( i => i.notes == this.editedItem.debit_acct_no );
        if (typeof dAcct !== 'undefined') {
          this.editedItem.debit_acct_no = { notes: dAcct.notes, comb_name: dAcct.comb_name }
        }
        let cAcct = this.accts.find( i => i.notes == this.editedItem.credit_acct_no );
        if (typeof cAcct !== 'undefined') {
          this.editedItem.credit_acct_no = { notes: cAcct.notes, comb_name: cAcct.comb_name }
        }
        let txnTy = this.txntys.find( i => i.code == this.editedItem.txn_type );
        this.editedItem.txn_type = { code: txnTy.code, name: txnTy.name }
        this.editedItem.cust_id = { id: this.editedItem.cust_id, name: this.editedItem.cust_name }
        if (txnTy.code == '102003') {
          this.editedItem.debit_amnt = this.editedItem.debit_amnt_usd
          this.editedItem.credit_amnt = this.editedItem.credit_amnt_usd
        }
        this.dialog = true
      },

      sClick () {
        this.selected = []
        if (!this.isActive) {
          this.getGrid();
        } else {
          this.getHaalt();
        }        
      },

      resetForm() {
        // this.$refs.form.reset()
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
      },
      
      // check vat transaction's details
      checkSelData(ids, retIds) {
        for (let key in ids) {
          this.gridRows.filter(x => Number.parseInt(x.notes) === ids[key]).forEach(function (p) {
            retIds.indexOf(p.id) === -1 ? retIds.push(p.id) : null;
          });
        }
        return retIds;
      },

      deleteItem (item) {
        if (this.isActive) {
          alert('Хаалтын гүйлгээ устгах боломжгүй.')
          return
        }
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        // this.editedIndex = this.gridRows.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        let ids2 = [];
        this.selected.forEach(function (e) {
          if (e.txn_type === '102002') {
            e.notes === '0' ? ids2.push(e.id) : ids.indexOf(Number.parseInt(e.notes)) === -1 ? ids.push(Number.parseInt(e.notes)) : null;
            
            ids.indexOf(e.id) === -1 ? ids.push(e.id) : null;
          } else {
            ids.push(e.id);
          }
        });

        // in case of main vat transaction
        let pIds = ids2.length > 0 ? this.checkSelData(ids2, ids) : ids;

        axios.delete('journal/' + pIds)
          .then(response => {
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.selected = []
            this.reloadMain()
            this.closeDelete()
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })
      },

      saveHaalt () {
        this.dialogHaalt = true
      },

      saveHaaltConfirm () {
        let orgId = JSON.parse(sessionStorage.getItem('user_org')).id;
        var dt_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        // let dt = new Date().toISOString().substr(0, 10);
        let year = dt_range[0].substr(0,4);

        let rows = [];
        this.filteredGridRows.forEach(function (e) {
            rows.push({"invoice_no":999999,"invoice_dt":dt_range[1],"txn_type":"102004","txn_value":e.txn_value,
                    "debit_acct_no":e.debit_acct_no,"credit_acct_no":e.credit_acct_no,"debit_amnt":Number(e.debit_amnt),"credit_amnt":Number(e.credit_amnt),
                    "curr_rate": 0, "status":"active","org_id":orgId,"cust_id":null,"cust_name":e.cust_name,"notes":year});
        });
        // console.log(JSON.stringify(rows))

        axios.post('journal/store', rows)
          .then(response => {
            alert('Хаалтын мэдээлэл амжилттай орууллаа.');
            this.dialogHaalt = false
          })
          .catch(error => {
            alert('Мэдээлэл оруулах үед алдаа гарлаа.');
            console.log(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeHaalt () {
        this.dialogHaalt = false
      },

      csvClose () {
        this.csvDialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      checkSelected (data) {
        let item = data.item
        let value = data.value
        if (item.txn_type === '102002') {
          this.selected = value ? this.selected.concat(this.gridRows.filter(x => x.invoice_no === item.invoice_no)) : this.selected.filter(x => x.invoice_no !== item.invoice_no)
          // alert(this.selected)
        }
      },

      enterSelect(values) {
        // alert(values)
        // alert(this.selected)

        // let ids = [];
        values.forEach(function (e) {
            //ids.push(e.id);
            if (e.txn_type === '102002') {
              this.selected.concat(this.gridRows.filter(x=> x.invoice_no === e.invoice_no))
              // ids.push(this.gridRows.filter(x => x.invoice_no === e.invoice_no && x.id !== e.id && ids.indexOf(x.id) === -1));
              // ids.push(e.invoice_no);
              // if (ids.indexOf(e.invoice_no) === -1) ids.push(e.invoice_no) 
              this.selected = values.indexOf(e) === -1 ? this.selected.concat(this.gridRows.filter(x => x.invoice_no === e.invoice_no)) : this.selected.filter(x => x.invoice_no !== e.invoice_no)
              // alert(this.selected)
            // } else {
            //   ids.push(e.id);
            }
        });
        alert(this.selected)

        // if (values.length == this.itemsPerPage) {
        //   alert('selected all')
        // }
      },

      checkAllSelected (event) {
        if (this.selected.length > 0) {
          alert('all selected!')
        } else {
          alert('all deselected!')
        }
        return
        let item = data.item
        let value = data.value
        if (item.txn_type === '102002') {
          this.selected = value ? this.selected.concat(this.gridRows.filter(x => x.invoice_no === item.invoice_no)) : this.selected.filter(x => x.invoice_no !== item.invoice_no)
          // alert(this.selected)
        }
      },

      checkTxnType (item) {
        return item.txn_type === '102004' || item.txn_type === '102002' && item.notes !== '0' ? false : true;
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        if (!this.editedItem.debit_amnt && !this.editedItem.credit_amnt) {
          alert('Дебит дүн / Кредит дүн аль нэг нь заавал утгатай байна.')
          return;
        }
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.editedItem.debit_acct_no = this.editedItem.debit_acct_no.notes;
        this.editedItem.credit_acct_no = this.editedItem.credit_acct_no.notes;
        this.editedItem.txn_type = this.editedItem.txn_type.code;
        this.editedItem.cust_name = this.editedItem.cust_id ? this.editedItem.cust_id.name : null;
        this.editedItem.cust_id = this.editedItem.cust_id ? this.editedItem.cust_id.id: null;
        if (this.editedIndex > -1) {
          axios.put('journal/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай засварлалаа.');
              this.reloadMain()
              // this.$router.go()
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Алдаа гарлаа.');
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
          this.close()
        } else {
          let tableRows = [];
          tableRows.push(this.editedItem)
          axios.post('journal/store', tableRows)
            .then(response => {
              alert('Мэдээлэл амжилттай орууллаа.');
              this.reloadMain()
              this.resetForm()
              // this.$router.go()
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Алдаа гарлаа.');
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        // this.close()
      },
      
      csvSave () {
        if (!this.chosenFile) { alert("Оруулах файлаа сонгоно уу."); }
        this.loadXls = true;

        var reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsBinaryString(this.chosenFile);
        reader.onload = () => {
          this.dataF = reader.result;

          var data = reader.result;
          var workbook = XLSX.read(data, { type: 'binary', cellDates: true }),
              sheetName = workbook.SheetNames[0],
              sheet = workbook.Sheets[sheetName];
          const file_data = XLSX.utils.sheet_to_json(sheet, { header:["txn_type","txn_type_nm","invoice_dt","cust_name","txn_value","debit_acct_no","credit_acct_no","debit_amnt","credit_amnt","curr_rate"], range: 1, defval: '' });
          let org_id = JSON.parse(sessionStorage.getItem('user_org')).id
          let dAcctC = 0;
          let cAcctC = 0;
          let rawDataC = 0;
          let amntC = 0;
          // let absDbAccts = [];
          // let absCdAccts = [];
          let absenceAccts = [];
          let boolCurRt = true;
          var nval = file_data.filter(x=> x.txn_type == 102001 || x.txn_type == 102002 || x.txn_type == 102003).map(obj => {
            rawDataC++;
            let rObj = {}
            rObj = obj
            // rObj.invoice_dt = moment(obj['invoice_dt']).add('days', 1).format('YYYYMMDD');
            rObj.invoice_dt = moment(obj['invoice_dt']).add(1, 'hours').format('YYYYMMDD');
            rObj.org_id = org_id
            rObj.cust_id = this.custs.find(x => x.name === obj['cust_name']) ? this.custs.find(x => x.name === obj['cust_name']).id : ''
            this.accts.find(i => i.notes == obj['debit_acct_no']) ? dAcctC++ : absenceAccts.push(obj['debit_acct_no']);
            this.accts.find(i => i.notes == obj['credit_acct_no']) ? cAcctC++ : absenceAccts.push(obj['credit_acct_no']);
            Number(obj['debit_amnt']) > 0 || Number(obj['credit_amnt']) > 0 ? amntC++ : 0;
            obj['txn_type'] == 102003 && (Number(obj['curr_rate']) <= 0 || isNaN(Number(obj['curr_rate']))) ? boolCurRt = false : 0;
            // console.info('>>>> currate: ' + !Number(obj['curr_rate']));
            // console.info('>>>> date: ' + common.dateIsValid(new Date(obj['invoice_dt'])));
            return rObj
          });
          // let resDa = "rawDataC: " + file_data.length + "; dAcctC: " + dAcctC + "; cAcctC: " + cAcctC + "; absDbAccts[" + absDbAccts + "]; absCdAccts[" + absCdAccts + "];";
          // console.info("amntC: " + amntC);
          let msgA = "";
          if (rawDataC != dAcctC || rawDataC != amntC || !boolCurRt) {
            msgA = "Файлаас оруулах үед алдаа гарлаа. Шалгаад дахин оруулна уу.\r";
            !boolCurRt ? msgA = msgA + "- Валютын гүйлгээний ханш хоосон байж болохгүй.\r" : msgA;
            rawDataC != amntC ? msgA = msgA + "- Дебит болон кредит мөнгөн дүн хоюулаа хоосон байж болохгүй.\r" : msgA;
            rawDataC != dAcctC || dAcctC != cAcctC ? msgA = msgA + "- Дараах данснууд бүртгэлгүй байна.\r  [" + absenceAccts + "]\r" : msgA;
            alert(msgA);
            return;
          }
          // if (rawDataC != dAcctC || dAcctC != cAcctC) {
          //   alert('Файлаас оруулах үед алдаа гарлаа.\rДараах данснууд бүртгэлгүй байна.\r[' + absenceAccts + ']');
          //   return;
          // }

          axios.post('journal/store', nval)
            .then(response => {
              // alert("[" + response.length + "][" + response.data.length + "]");
              alert('Файлаас мэдээлэл амжилттай орууллаа.\r- Нийт [' + rawDataC + '] гүйлгээнээс [' + response.data.length + '] гүйлгээ орсон.\r');
              this.loadXls = false;
              this.reloadMain();
            })
            .catch(error => {
              alert('Файлаас мэдээлэл оруулах үед алдаа гарлаа.\r[' + error.message + ']');
              this.loadXls = false;
              console.log(error)
            })
        }

        this.csvClose()
      },
      
      reloadMain() {
        this.initialize();
      },

      
      wholePrint() {
        if (this.filteredGridRows.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        var org = this.orgs.filter(x=> x.id==org_id)[0]

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 0.5, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 8.0, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Аж ахуйн нэгжийн нэр)', 0.5, 0.6);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Тайлант үе)', 8.0, 0.6);
        // create a line under heading 
        doc.setLineWidth(0.001).line(0.5, 0.7, 11.0, 0.7);
        // set selected account number and customer name
        doc.setFont("Arial-Mon", "bold").setFontSize(13).text('ЕРӨНХИЙ ЖУРНАЛ', 0.5, 1.0);
        
        // set headers and data for autoTable
        const headers2 = [
          ['Баримтын дугаар', 'Огноо', 'Гүйлгээний утга', 'Харилцагч', 'Дебит данс', 'Кредит данс', 'Дебит дүн', 'Кредит дүн']
        ];
        let data2 = [];
        this.filteredGridRows.forEach(function (e) {
          var ids = [];
          ids.push(e.invoice_no);
          ids.push(e.invoice_dt);
          ids.push(e.txn_value);
          ids.push(e.cust_name);
          ids.push(e.debit_acct_no);
          ids.push(e.credit_acct_no);
          ids.push(common.moneyFormat(e.debit_amnt));
          ids.push(common.moneyFormat(e.credit_amnt));
          data2.push(ids);
        });

        if (data2.length == 0) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, body: data2, margin: { left: 0.5, top: 1.1 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 9} })
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 4.5, finalY+0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 4.5, finalY+0.8);
        
        // saving file
        doc.save(`journal.pdf`);
      },

    },
  }
</script>