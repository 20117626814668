export default {
    moneyFormat(value) {
      if(!value) return;
      let val = value
      return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    moneyFormatNum(value, num) {
      if(!value) return;
      let val = value
      return Number(val).toFixed(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    moneyFormat2(value) {
      if(!value) return;
      let val = value
      return Number(val).toFixed(2)
    },

    inWords(num) {
      var a = ['','нэг ','хоёр ','гурав ','дөрөв ', 'тав ','зургаа ','долоо ','найм ','ес '];
      var a1 = ['','нэг ','хоёр ','гурван ','дөрвөн ', 'таван ','зургаан ','долоон ','найман ','есэн '];
      var b = ['', 'арав ', 'хорь ','гуч ','дөч ','тавь ', 'жар ','дал ','ная ','ер '];
      var c = ['', 'арван ', 'хорин ','гучин ','дөчин ','тавин ', 'жаран ','далан ','наян ','ерэн '];

      if ((num = num.toString()).length > 15) return 'overflow';
      var n = ('000000000000' + num).substr(-15).substr(0, 12).match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/);
      var n2 = ('000000000000' + num).substr(-2).match(/^(\d{2})$/);
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a1[Number(n[1])] || (n[1][0] != 0 ? a1[n[1][0]] + 'зуун ' : '') + c[n[1][1]] + a1[n[1][2]]) + 'тэрбум ' : '';
      str += (n[2] != 0) ? (a1[Number(n[2])] || (n[2][0] != 0 ? a1[n[2][0]] + 'зуун ' : '') + c[n[2][1]] + a1[n[2][2]]) + 'сая ' : '';
      str += (n[3] != 0) ? (a1[Number(n[3])] || (n[3][0] != 0 ? a1[n[3][0]] + 'зуун ' : '') + c[n[3][1]] + a1[n[3][2]]) + 'мянга ' : '';
      str += (n[4] != 0) ? (a1[Number(n[4])] || (n[4][0] != 0 ? a1[n[4][0]] + 'зуун ' : '') + c[n[4][1]] + a1[n[4][2]]) + ' ' : '';
      str += 'төгрөг ';
      if(n2) str += (n2[1] != 0) ? (c[n2[1][0]] + a1[n2[1][1]] ) + 'мөнгө' : '';
      return str;
    }
}