<template>
  <div class="tabs pa-5">
    <v-tabs
      v-model="activeTab"
      hide-slider
      height="0"
    >
      <v-tab
        v-for="tab of tabs"
        :key="tab.id"
        :to="tab.route"
        disabled
        exact
      >
        <v-icon small class="mr-1">{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
      <v-tab-item
        v-for="tab of tabs"
        :key="tab.id"
        :value="tab.route"
      >
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
    <v-btn
      v-for="btn of tabs"
      :key="btn.id"
      :to="btn.route"
    >
      <v-icon small class="mr-1">{{ btn.icon }}</v-icon>
      {{ btn.name }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        activeTab: `/rprttabs`,
        tabs: [
          { id: '1', name: 'Санхүүгийн байдал', route: `/rprttabs/finance`, icon: 'mdi-finance' },
          { id: '2', name: 'Орлогын дэлгэрэнгүй', route: `/rprttabs/income`, icon: 'mdi-cash-100' },
          { id: '3', name: 'Өмчийн өөрчлөлт', route: `/rprttabs/assets`, icon: 'mdi-office-building' },
          { id: '4', name: 'Мөнгөн гүйлгээ', route: `/rprttabs/money`, icon: 'mdi-currency-mnt' }
        ],
      };
    }
  };
</script>
