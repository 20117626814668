<template>
  <div class="tabs pa-5">
    <v-tabs
      v-model="activeTab"
      hide-slider
      height="0"
    >
      <v-tab
        v-for="tab of tabs"
        :key="tab.id"
        :to="tab.route"
        disabled
        exact
      >
        <!-- <v-icon>{{ tab.icon }}</v-icon>
        {{ tab.name }} -->
      </v-tab>
      <v-tab-item
        v-for="tab of tabs"
        :key="tab.id"
        :value="tab.route"
      >
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
    <v-btn
      v-for="btn of tabs"
      :key="btn.id"
      :to="btn.route"
      :color="btn.bgcolor"
    >
      <v-icon small class="mr-1">{{ btn.icon }}</v-icon>
      {{ btn.name }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        activeTab: `/tabs`,
        tabs: [
          { id: '1', name: 'Баланс', route: `/tabs/balance`, icon: 'mdi-scale-balance', bgcolor: 'orange lighten-5' },
          { id: '2', name: 'Журнал', route: `/tabs/journal`, icon: 'mdi-animation-play-outline', bgcolor: 'green lighten-5' },
          { id: '3', name: 'Хуулга', route: `/tabs/statement`, icon: 'mdi-bank-transfer', bgcolor: 'green lighten-5' },
          // { id: '4', name: 'Падаан', route: `/tabs/receipt`, icon: 'mdi-receipt', bgcolor: 'green lighten-5' },
          { id: '5', name: 'Цалин', route: `/saltabs/salary`, icon: 'mdi-cash-multiple', bgcolor: 'yellow lighten-5' },
          // { id: '6', name: 'Хөрөнгө', route: `/tabs/asset`, icon: 'mdi-domain', bgcolor: 'pink lighten-5' },
          { id: '7', name: 'Тайлан', route: '/rprttabs/finance', icon: 'mdi-file-chart', bgcolor: 'blue lighten-5' }
        ],
      };
    }
  };
</script>
