<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="invoice_dt"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    show-select
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Үндсэн хөрөнгийн бүртгэл</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <!-- <v-spacer></v-spacer> -->

        <v-row align="center">
          <v-col cols="2">
            <v-subheader>
              Хөрөнгийн ангилал:
            </v-subheader>
          </v-col>
          <v-col cols="7">
            <v-select
              v-model="select"
              :hint="`${select.code}`"
              :items="assets"
              item-text="name"
              item-value="code"
              label="Ангилал сонгоно уу?"
              persistent-hint
              return-object
              append-icon="mdi-select-marker"
              single-line
              v-on:change="changeAsset"
            ></v-select>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>


        
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              нэмэх
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                  >
                    <v-combobox
                      v-model="select"
                      label="Хөрөнгийн ангилал"
                      hide-details
                      :items="assets"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                      readonly
                      :return-object="false"
                    >
                      <!-- <template slot="item" slot-scope="data">
                        {{data.item.code}}: {{data.item.name}}
                      </template> -->
                    </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.parent_code"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Хөрөнгийн нэр"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.child_code"
                      label="Код"
                      :rules="[rules.required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.owner"
                      label="Эзэмшигч"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.location"
                      label="Байршил"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.use_start_dt"
                          label="Ашиглаж эхэлсэн огноо"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.invoice_dt"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.valid_years"
                      label="Ажиллах жил"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.frst_bal_count"
                      label="Эхний үлдэгдэл тоо"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.frst_bal_unit_price"
                      label="Эхний үлдэгдэл нэгж үнэ"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="frstBalTotlAmnt"
                      label="Эхний үлдэгдэл нийт үнэ"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.inco_bal_count"
                      label="Орлого тоо"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.inco_bal_unit_price"
                      label="Орлого нэгж үнэ"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="incoBalTotlAmnt"
                      label="Орлого нийт үнэ"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.finl_bal_count"
                      label="Эцсийн үлдэгдэл тоо"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.finl_bal_unit_price"
                      label="Эцсийн үлдэгдэл нэгж үнэ"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="finlBalTotlAmnt"
                      label="Эцсийн үлдэгдэл нийт үнэ"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.est_depn"
                      label="Байгуулсан элэгдэл"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.accum_depn"
                      label="Хуримтлагдсан элэгдэл"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.bal_amnt"
                      label="Үлдэгдэл дүн"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="red lighten-2"
            dark
            class="mb-2 ml-2"
            @click="deleteItem"
          >
            Устгах
            <v-icon small class="ml-1">mdi-delete</v-icon>
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон гүйлгээний мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-toolbar>
    </template>
    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th>
          <v-icon>mdi-filter-variant</v-icon>
				</th>
        <th v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
              no-data-text="дата байхгүй байна"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 5">
                  <span class="small--text"> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
    <!-- <template v-slot:header="props" >
      <thead>
        <tr>
          <th colspan="2">Category 1</th>
          <th colspan="3">Category 2</th>
        </tr>
      </thead>
    </template> -->

    <template v-slot:item.debit_amnt="{ item }">
      <span>{{formatPrice(item.debit_amnt)}}</span>
    </template>
    <template v-slot:item.credit_amnt="{ item }">
      <span>{{formatPrice(item.credit_amnt)}}</span>
    </template>
    <template v-slot:item.last_amnt="{ item }">
      <span>{{formatPrice(totalBalance(item))}}</span>
    </template>
    <!-- <template v-slot:item.cust_id="{ item }">
      <span>{{custName(item.cust_id)}}</span>
    </template> -->

    <template v-slot:body.append>
      <tr class="bold--text">
        <th />
        <th />
        <th class="text-left">Дүн</th>
        <th />
        <th />
        <th />
        <th />
        <th class="text-right">{{ formatPrice(sumField('frst_bal_count')) }}</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('frst_bal_totl_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('accum_depn')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('inco_bal_count')) }}</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('inco_bal_totl_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('finl_bal_count')) }}</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('finl_bal_totl_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('est_depn')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('accum_depn')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('bal_amnt')) }}</th>
        <th />
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';

  let bgColorCode = "orange lighten-5";

  export default {
    props: ['dateRange'],
    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      assets: [],
      txntys: [],
      custs: [],
      select: { name: 'Барилга байгууламж', code: '106001' },
      // select: '106001',
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Хөрөнгийн нэр', value: 'name', divider: true, class: bgColorCode, width: '110px' },
        { text: 'Код', value: 'child_code', divider: true, class: bgColorCode },
        { text: 'Эзэмшигч', value: 'owner', divider: true, class: bgColorCode },
        { text: 'Байршил', value: 'location', divider: true, class: bgColorCode, width: '1%' },
        { text: 'Ашиглаж эхэлсэн огноо', value: 'use_start_dt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Ажиллах жил', value: 'valid_years', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эхний үлдэгдэл тоо', value: 'frst_bal_count', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эхний үлдэгдэл нэгж үнэ', value: 'frst_bal_unit_price', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эхний үлдэгдэл нийт үнэ', value: 'frst_bal_totl_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Хуримтлагдсан элэгдэл', value: 'accum_depn', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Орлого тоо', value: 'inco_bal_count', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Орлого нэгж үнэ', value: 'inco_bal_unit_price', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Орлого нийт үнэ', value: 'inco_bal_totl_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эцсийн үлдэгдэл тоо', value: 'finl_bal_count', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эцсийн үлдэгдэл нэгж үнэ', value: 'finl_bal_unit_price', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эцсийн үлдэгдэл нийт үнэ', value: 'finl_bal_totl_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Байгуулсан элэгдэл', value: 'est_depn', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Хуримтлагдсан элэгдэл', value: 'accum_depn', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Үлдэгдэл дүн', value: 'bal_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, align: 'center', width: '1%' },
      ],
      gridRows: [],
      filters: {
        name: [],
        child_code: [],
				owner: [],
				location: [],
				use_start_dt: [],
        valid_years: [],
				frst_bal_count: [],
				frst_bal_unit_price: [],
				frst_bal_totl_amnt: [],
				accum_depn: [],
        inco_bal_count: [],
				inco_bal_unit_price: [],
				inco_bal_totl_amnt: [],
        finl_bal_count: [],
				finl_bal_unit_price: [],
				finl_bal_totl_amnt: [],
        est_depn: [],
				accum_depn: [],
				bal_amnt: [],
			},
      editedIndex: -1,
      editedItem: {
        name: '',
        parent_code: '106001',
        child_code: '',
        owner: '',
        location: '',
        use_start_dt: new Date().toISOString().substr(0, 10),
        valid_years: '',
        frst_bal_count: '',
        frst_bal_unit_price: '',
        frst_bal_totl_amnt: '',
        accum_depn: '',
        inco_bal_count: '',
        inco_bal_unit_price: '',
        inco_bal_totl_amnt: '',
        outo_bal_count: '0',
        outo_bal_unit_price: '0',
        outo_bal_totl_amnt: '0',
        finl_bal_count: '',
        finl_bal_unit_price: '',
        finl_bal_totl_amnt: '',
        est_depn: '',
        bal_amnt: '',
        org_id: '',
        is_parent: '0',
      },
      defaultItem: {
        name: '',
        parent_code: '106001',
        child_code: '',
        owner: '',
        location: '',
        use_start_dt: new Date().toISOString().substr(0, 10),
        valid_years: '',
        frst_bal_count: '',
        frst_bal_unit_price: '',
        frst_bal_totl_amnt: '',
        accum_depn: '',
        inco_bal_count: '',
        inco_bal_unit_price: '',
        inco_bal_totl_amnt: '',
        outo_bal_count: '0',
        outo_bal_unit_price: '0',
        outo_bal_totl_amnt: '0',
        finl_bal_count: '',
        finl_bal_unit_price: '',
        finl_bal_totl_amnt: '',
        est_depn: '',
        bal_amnt: '',
        org_id: '',
        is_parent: '0',
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        number: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid number.'
        },
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
        return this.gridRows.filter((i) => {
          return !this.select || (i.parent_code === this.select.code);
        }).filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
				// return this.gridRows.filter((d) => {
				// 	return Object.keys(this.filters).every((f) => {
				// 		return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
				// 	});
				// });
			},
      frstBalTotlAmnt () {
        this.editedItem.frst_bal_totl_amnt = this.editedItem.frst_bal_unit_price * this.editedItem.frst_bal_count
        return this.editedItem.frst_bal_totl_amnt
      },
      incoBalTotlAmnt () {
        this.editedItem.inco_bal_totl_amnt = this.editedItem.inco_bal_unit_price * this.editedItem.inco_bal_count
        return this.editedItem.inco_bal_totl_amnt
      },
      finlBalTotlAmnt () {
        this.editedItem.finl_bal_totl_amnt = this.editedItem.finl_bal_unit_price * this.editedItem.finl_bal_count
        return this.editedItem.finl_bal_totl_amnt
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.getOptions()
      // this.getCustInfo()
      this.initialize()
    },

    methods: {
      initialize () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        // let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          // s_dt: user_dt[0],
          // f_dt: user_dt[1]
        };
        axios.get('assets', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          })
      },

      customFilter(items, search, filter) {
        search = search.toString().toLowerCase()
        return items.filter(row => filter(row["debit_acct_no"], search));
      },

      formatPrice(value) {
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      custName(value) {
        return this.custs.find(x => x.id === value).name;
      },

      totalBalance(item) {
        // =IF(B$4<300000,J7+G10-H10,IF(700000>B$4,J7+H10-G10,J7+G10-H10))
        return item.debit_amnt > item.credit_amnt ? (item.debit_amnt-item.credit_amnt) : '-'
      },

      getCustInfo () {
        axios.get('customers')
          .then(response => {
            this.custs = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      getOptions () {
        axios.get('commoncode/parent_code/106')
          .then(response => {
            this.assets = response.data;
          })
          .catch(error => {
            console.log(error)
          })

        // axios.get('http://localhost/efback/public/api/commoncode/parent_code/102')
        //   .then(response => {
        //     this.txntys = response.data;
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      changeAsset(asset) {
        this.select = asset
        this.editItem.parent_code = asset.code
        // this.gridRows = this.gridRows.filter(x=>x.notes = acct.notes)
        // return this.gridRows.filter((i) => {
        //   return !this.select || (i.debit_acct_no === this.select);
        // })
      },

      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => a + (b[key] || 0), 0)
      },

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // this.gridRows.splice(this.editedIndex, 1)
        // this.closeDelete()
        let sCount = 0
        this.selected.forEach(element => {
          axios.delete('asset/' + element.id)
            .then(response => {
              sCount ++;
              console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
        });
        if (this.selected.length == sCount) {
          alert('Successfully deleted!')
          this.$router.go()
          this.closeDelete()
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.editedItem.parent_code = this.select.code;
        if (this.editedIndex > -1) {
          axios.put('asset/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              this.$router.go()
              console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          axios.post('asset/store', this.editedItem)
            .then(response => {
              this.$router.go()
              console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>