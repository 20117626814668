<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="invoice_dt"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    show-select
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Цалингийн хүснэгт</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <!-- <v-spacer></v-spacer> -->
        <v-row align="center">
          <!-- <v-col cols="2">
            <v-subheader>
              Сар:
            </v-subheader>
          </v-col> -->
          <v-col cols="9">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.salr_dt"
                  label="Тайлант сар"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editedItem.salr_dt"
                type="month"
                @input="menu2 = false"
                v-on:change="changeMonth"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows"
          :fields="exp_fields"
          :labels="exp_labels"
          name="salary.csv"
        >
          <v-btn color="warning lighten-2" dark class="mb-1 mr-1">
            EXPORT
            <!-- Export<i class="mdi mdi-export-variant" aria-hidden="true"></i> -->
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->
        <v-btn
          color="blue lighten-2"
          dark
          class="mb-1 mr-1"
          @click="wholePrint()"
        >
          PDF
          <!-- PDF <i class="mdi mdi-printer" aria-hidden="true"></i> -->
        </v-btn>

        <!-- Export (start) -->
        <!-- <vue-json-csv
          class="btn btn-default"
          :data="tt11GridRows"
          :fields="export_fields"
          :labels="export_labels"
          name="report_tt11.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-1">
            ТТ-11 <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv> -->
        <!-- Export (end) -->

        <download-excel
         :data="tt11GridRows2"
         :fields="export_labels2a"
         name="tt11.xls"
         worksheet="TT11"
        >
          <v-btn color="error lighten-2" dark class="mb-1 mr-1">
            ТТ-11
             <!-- <i class="mdi mdi-export-variant" aria-hidden="true"></i> -->
          </v-btn>
        </download-excel>


        <v-dialog
          v-model="dialog"
          max-width="800px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-1"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              нэмэх
              <!-- <v-icon small class="ml-1">mdi-plus-thick</v-icon> -->
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-btn
                      color="primary lighten-2"
                      dark
                      @click="dialog2 = !dialog2"
                    >
                      Ажилтаны мэдээлэл сонгох
                      <v-icon>mdi-arrow-bottom-right-thin-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <!-- <v-text-field
                      v-model="editedItem.insr_type_nm"
                      label="Даатгуулагчийн төрөл *"
                      :rules="[required]"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field> -->
                    <v-combobox
                      v-model="editedItem.insr_type_nm"
                      label="Даатгуулагчийн төрөл *"
                      :items="insrType"
                      item-text="name"
                      item-value="code"
                      :rules="[required]"
                      outlined
                      dense
                      background-color="blue lighten-5"
                      hide-details
                      readonly
                    >
                    </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.lastname"
                      label="Овог *"
                      :rules="[required]"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.firstname"
                      label="Нэр *"
                      :rules="[required]"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.position"
                      label="Албан тушаал"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col> -->
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.reg_num"
                      label="Татвар төлөгчийн дугаар *"
                      :rules="[required]"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.socl_insr_bk_no"
                      label="НДД-н дугаар"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.hlth_insr_bk_no"
                      label="ЭМДД-н дугаар"
                      outlined
                      dense
                      readonly
                      background-color="blue lighten-5"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.salr_dt"
                          label="Тайлант сар *"
                          :rules="[required]"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.salr_dt"
                        type="month"
                        @input="menu3 = false"
                      ></v-date-picker>
                      <!-- <v-date-picker
                        v-model="picker"
                        type="month"
                        color="green lighten-1"
                      ></v-date-picker> -->
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.work_days"
                      label="Ажиллах өдөр *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      hide-details
                      v-on="workDays"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.work_hours"
                      label="Ажиллах цаг *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.worked_days"
                      label="Ажилласан өдөр *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      hide-details
                      v-on="workedDays"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.worked_hours"
                      label="Ажилласан цаг *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="editedItem.basc_salary"
                      label="Үндсэн цалин *"
                      :rules="[required]"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.addn_salary"
                      label="Нэмэгдэл цалин"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="totlSalary"
                      label="Нийт цалин *"
                      :rules="[required]"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.othr_addn_amnt"
                      label="Бусад нэмэгдэл/ээлжийн амралт/"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="estmSalary"
                      label="Олгохоор тооцсон цалин *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="soclInsrEmplAmnt"
                      label="НДШ ажилтан"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.socl_insr_rate_empl"
                      label="НДШ хувь"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="psnlIncoTaxAmnt"
                      label="ХХОАТ *"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.psnl_inco_tax_rate"
                      label="ХХОАТ хувь"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.deduction1"
                      label="Суутгал 1"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.deduction2"
                      label="Суутгал 2"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="totlDeductionAmnt"
                      label="Суутгалын дүн"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="handPaySalary"
                      label="Гарт олгох цалин *"
                      :rules="editedItem.notes != 501003 && editedItem.notes != 501004 && editedItem.notes != 501007 && editedItem.notes != 501205 && editedItem.notes != 501208 && editedItem.notes != 501209 && editedItem.notes != 501210 && editedItem.notes != 501213 && editedItem.notes != 501216 && editedItem.notes != 501217 ? [required] : []"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <!-- <v-text-field
                      v-model="taxCredit"
                      label="Татварын хөнгөлөлт"
                      type="number"
                      outlined
                      dense
                      hide-details
                      v-on="workDays"
                    ></v-text-field> -->
                    <v-text-field
                      v-model="editedItem.tax_credit"
                      label="Татварын хөнгөлөлт"
                      type="number"
                      outlined
                      dense
                      hide-details
                      v-on="taxCredit"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.psnl_inco_tax_exempt_amnt"
                      label="Чөлөөлөгдөх ХХОАТ-н дүн"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="soclInsrOrgAmnt"
                      label="НДШ байгууллага"
                      type="number"
                      outlined
                      dense
                      readonly
                      background-color="grey lighten-4"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.org_socl_insr_rate"
                      label="НДШ байгууллага хувь"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.signature"
                      label="Гарын үсэг"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog2"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              Ажилтан сонгох хэсэг
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                :items="empls"
                label="Ажилтан"
                item-text="cname"
                return-object
                clearable
                no-data-text="Тохирох утга олдсонгүй!"
                v-on:change="selectedEmployer"
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="dialog2 = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="red lighten-2"
            dark
            class="mb-1 ml-1"
            v-if="!readonly_check"
            @click="deleteItem"
          >
            Устгах
            <!-- <v-icon small class="ml-1">mdi-delete</v-icon> -->
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон цалингийн мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8">
          <v-icon small>mdi-filter-variant</v-icon>
				</th>
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="!readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>

    <template v-slot:item.basc_salary="{ item }">
      <span>{{formatPrice(item.basc_salary)}}</span>
    </template>
    <template v-slot:item.addn_salary="{ item }">
      <span>{{formatPrice(item.addn_salary)}}</span>
    </template>
    <template v-slot:item.totl_salary="{ item }">
      <span>{{formatPrice(item.totl_salary)}}</span>
    </template>
    <template v-slot:item.othr_addn_amnt="{ item }">
      <span>{{formatPrice(item.othr_addn_amnt)}}</span>
    </template>
    <template v-slot:item.estm_salary="{ item }">
      <span>{{formatPrice(item.estm_salary)}}</span>
    </template>
    <template v-slot:item.socl_insr_empl_amnt="{ item }">
      <span>{{formatPrice(item.socl_insr_empl_amnt)}}</span>
    </template>
    <template v-slot:item.psnl_inco_tax_amnt="{ item }">
      <span>{{formatPrice(item.psnl_inco_tax_amnt)}}</span>
    </template>
    <template v-slot:item.deduction1="{ item }">
      <span>{{formatPrice(item.deduction1)}}</span>
    </template>
    <template v-slot:item.deduction2="{ item }">
      <span>{{formatPrice(item.deduction2)}}</span>
    </template>
    <template v-slot:item.totl_deduction_amnt="{ item }">
      <span>{{formatPrice(item.totl_deduction_amnt)}}</span>
    </template>
    <template v-slot:item.hand_pay_salary="{ item }">
      <span>{{formatPrice(item.hand_pay_salary)}}</span>
    </template>
    <template v-slot:item.tax_credit="{ item }">
      <span>{{formatPrice(item.tax_credit)}}</span>
    </template>
    <template v-slot:item.psnl_inco_tax_exempt_amnt="{ item }">
      <span>{{formatPrice(item.psnl_inco_tax_exempt_amnt)}}</span>
    </template>
    <template v-slot:item.org_socl_insr_amnt="{ item }">
      <span>{{formatPrice(item.org_socl_insr_amnt)}}</span>
    </template>

    <template v-slot:body.append>
      <tr class="bold--text">
        <th />
        <th />
        <th />
        <th class="text-left">нийт дүн</th>
        <th />
        <th />
        <th />
        <th />
        <th />
        <th />
        <th class="text-right">{{ formatPrice(sumField('basc_salary')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('addn_salary')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('totl_salary')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('othr_addn_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('estm_salary')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('socl_insr_empl_amnt')) }}</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('psnl_inco_tax_amnt')) }}</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('deduction1')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('deduction2')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('totl_deduction_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('hand_pay_salary')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('tax_credit')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('psnl_inco_tax_exempt_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('org_socl_insr_amnt')) }}</th>
        <th />
        <th />
        <th />
      </tr>
    </template>

    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'
  
  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      picker: new Date().toISOString().substr(0, 7),
      menu2: false,
      menu3: false,
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      search: '',
      selected: [],
      selectedTax: [],
      insrType: [],
      orgs: [],
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode
        },
        // { text: 'Тайлант сар', value: 'salr_dt', divider: true, class: bgColorCode },
        // { text: 'Даатгуулагчийн төрөл', value: 'insr_type_nm', divider: true, class: bgColorCode },
        { text: 'Овог', value: 'lastname', divider: true, class: bgColorCode },
        { text: 'Нэр', value: 'firstname', divider: true, class: bgColorCode },
        { text: 'Татвар төлөгчийн дугаар', value: 'reg_num', divider: true, class: bgColorCode },
        // { text: 'НДД-н дугаар', value: 'socl_insr_bk_no', divider: true, class: bgColorCode },
        // { text: 'ЭМДД-н дугаар', value: 'hlth_insr_bk_no', divider: true, class: bgColorCode },
        { text: 'Албан тушаал', value: 'position', divider: true, class: bgColorCode },
        { text: 'Ажиллах өдөр', value: 'work_days', divider: true, class: bgColorCode },
        { text: 'Ажиллах цаг', value: 'work_hours', divider: true, class: bgColorCode },
        { text: 'Ажилласан өдөр', value: 'worked_days', divider: true, class: bgColorCode },
        { text: 'Ажилласан цаг', value: 'worked_hours', divider: true, class: bgColorCode },
        { text: 'Үндсэн цалин', value: 'basc_salary', divider: true, class: bgColorCode },
        { text: 'Нэмэгдэл цалин', value: 'addn_salary', divider: true, class: bgColorCode },
        { text: 'Нийт цалин', value: 'totl_salary', divider: true, class: bgColorCode },
        { text: 'Бусад нэмэгдэл/ээлжийн амралт/', value: 'othr_addn_amnt', divider: true, class: bgColorCode },
        { text: 'Олгохоор тооцсон цалин', value: 'estm_salary', divider: true, class: bgColorCode },
        { text: 'НДШ ажилтан', value: 'socl_insr_empl_amnt', divider: true, class: bgColorCode },
        { text: 'НДШ хувь', value: 'socl_insr_rate_empl', divider: true, class: bgColorCode },
        { text: 'ХХОАТ', value: 'psnl_inco_tax_amnt', divider: true, class: bgColorCode },
        { text: 'ХХОАТ хувь', value: 'psnl_inco_tax_rate', divider: true, class: bgColorCode },
        { text: 'Суутгал 1', value: 'deduction1', divider: true, class: bgColorCode },
        { text: 'Суутгал 2', value: 'deduction2', divider: true, class: bgColorCode },
        { text: 'Суутгалын дүн', value: 'totl_deduction_amnt', divider: true, class: bgColorCode },
        { text: 'Гарт олгох цалин', value: 'hand_pay_salary', divider: true, class: bgColorCode },
        { text: 'Татварын хөнгөлөлт', value: 'tax_credit', divider: true, class: bgColorCode },
        { text: 'Чөлөөлөгдөх ХХОАТ-н дүн', value: 'psnl_inco_tax_exempt_amnt', divider: true, class: bgColorCode },
        { text: 'НДШ байгууллага', value: 'org_socl_insr_amnt', divider: true, class: bgColorCode },
        { text: 'НДШ байгууллага хувь', value: 'org_socl_insr_rate', divider: true, class: bgColorCode },
        { text: 'Гарын үсэг', value: 'signature', divider: true, class: bgColorCode },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode },
      ],
      exp_fields: [
        'salr_dt', 'lastname', 'firstname', 'reg_num', 'position', 'work_days', 'work_hours', 'worked_days', 'worked_hours', 'basc_salary', 'addn_salary',
        'totl_salary', 'othr_addn_amnt', 'estm_salary', 'socl_insr_empl_amnt', 'socl_insr_rate_empl', 'psnl_inco_tax_amnt', 'psnl_inco_tax_rate',
        'deduction1', 'deduction2', 'totl_deduction_amnt', 'hand_pay_salary', 'tax_credit', 'psnl_inco_tax_exempt_amnt', 'org_socl_insr_amnt',
        'org_socl_insr_rate', 'signature'
      ],
      exp_labels: {
        salr_dt: 'Тайлант сар',
        lastname: 'Овог',
        firstname: 'Нэр',
        reg_num: 'Татвар төлөгчийн дугаар',
        position: 'Албан тушаал',
        work_days: 'Ажиллах өдөр',
        work_hours: 'Ажиллах цаг',
        worked_days: 'Ажилласан өдөр',
        worked_hours: 'Ажилласан цаг',
        basc_salary: 'Үндсэн цалин',
        addn_salary: 'Нэмэгдэл цалин',
        totl_salary: 'Нийт цалин',
        othr_addn_amnt: 'Бусад нэмэгдэл/ээлжийн амралт/',
        estm_salary: 'Олгохоор тооцсон цалин',
        socl_insr_empl_amnt: 'НДШ ажилтан',
        socl_insr_rate_empl: 'НДШ хувь',
        psnl_inco_tax_amnt: 'ХХОАТ',
        psnl_inco_tax_rate: 'ХХОАТ хувь',
        deduction1: 'Суутгал 1',
        deduction2: 'Суутгал 2',
        totl_deduction_amnt: 'Суутгалын дүн',
        hand_pay_salary: 'Гарт олгох цалин',
        tax_credit: 'Татварын хөнгөлөлт',
        psnl_inco_tax_exempt_amnt: 'Чөлөөлөгдөх ХХОАТ-н дүн',
        org_socl_insr_amnt: 'НДШ байгууллага',
        org_socl_insr_rate: 'НДШ байгууллага хувь',
        signature: 'Гарын үсэг',
      },
      export_fields: [
        'tin', 'reg_num', 'lastname', 'firstname', 'law711', 'law712', 'law716', 'totl', 'tax_rate', 'tax_amnt',
        'tax_income', 'totl_tax_amnt', 'month', 'law231', 'law231_totl', 'aftr_tax_amnt', 'law716_amnt', 'totl_amnt'
      ],
      export_labels: {
        tin: 'ТИН ДУГААР',
        reg_num: 'Татвар төлөгчийн дугаар',
        lastname: 'Овог',
        firstname: 'Нэр',
        law711: 'хуулийн 7.1.1',
        law712: 'Хуулийн 7.1.2, 7.1.3, 7.1.4, 7.1.5, 7.1.7',
        law716: 'Хуулийн 7.1.6',
        totl: 'Нийт (1+2+3)',
        tax_rate: 'ЭМД, НДШ Хувь',
        tax_amnt: 'ЭМД, НДШ Дүн',
        tax_income: 'Татвар ногдуулах орлого',
        totl_tax_amnt: 'Ногдуулсан татвар',
        month: 'Орлого хүлээн авсан сарын тоо /ажилласан сар/',
        law231: 'Хуулийн 23.1-т заасан хөнгөлөлт сард ногдох',
        law231_totl: 'Хуулийн 23.1-т заасан хөнгөлөлт нийт',
        aftr_tax_amnt: 'Хөнгөлөлтийн дараах татварын дүн',
        law716_amnt: 'Хуулийн 7.1.6-д заасан орлогод ногдуулсан дүн',
        totl_amnt: 'Нийт суутгуулсан албан татварын дүн',
      },
      export_labels2: {
        'ТИН ДУГААР': 'tin',
        'Татвар төлөгчийн дугаар': 'reg_num',
        'Овог': 'lastname',
        'Нэр': 'firstname',
        'хуулийн 7.1.1': 'law711',
        'Хуулийн 7.1.2, 7.1.3, 7.1.4, 7.1.5, 7.1.7': 'law712',
        'Хуулийн 7.1.6': 'law716',
        'Нийт (1+2+3)': 'totl',
        'ЭМД, НДШ Хувь': 'tax_rate',
        'ЭМД, НДШ Дүн': 'tax_amnt',
        'Татвар ногдуулах орлого': 'tax_income',
        'Ногдуулсан татвар': 'totl_tax_amnt',
        'Орлого хүлээн авсан сарын тоо /ажилласан сар/': 'month',
        'Хуулийн 23.1-т заасан хөнгөлөлт сард ногдох': 'law231',
        'Хуулийн 23.1-т заасан хөнгөлөлт нийт': 'law231_totl',
        'Хөнгөлөлтийн дараах татварын дүн': 'aftr_tax_amnt',
        'Хуулийн 7.1.6-д заасан орлогод ногдуулсан дүн': 'law716_amnt',
        'Нийт суутгуулсан албан татварын дүн': 'totl_amnt',
      },
      export_labels2a: {
        'Татвар төлөгчийн дугаар': 'tin',
        'Овог': 'lastname',
        'Нэр': 'firstname',
        'хуулийн 7.1.1': 'law711',
        'Хуулийн 7.1.2, 7.1.3, 7.1.4, 7.1.5, 7.1.7': 'law712',
        'Хуулийн 7.1.6': 'law716',
        'Нийт (1+2+3)': 'totl',
        'ЭМД, НДШ Хувь': 'tax_rate',
        'ЭМД, НДШ Дүн (Хуулийн 7,1,1-5, 7,1,7)': 'tax_amnt',
        'ЭМД, НДШ Дүн (Хуулийн 7,1,6)': 'tax_amnt716',
        'Хуулийн 7.1-д заасан орлогод татвар ногдуулах орлого (4-6-7)': 'tax_income',
        'Орлогын төрөл': 'income_type',
        'Орлого': 'income',
        'Нийт татвар ногдуулах орлого': 'totl_tax_amnt',
        'Шатлал': 'stage',
        'Хуулийн 7.1.1, 7.1.5, 7.1.7-д заасан орлогод ногдуулсан татвар': 'tax_711_715_717',
        'Орлого хүлээн авсан сарын тоо /ажилласан сар/': 'month',
        'Хуулийн 23.1-т заасан хөнгөлөлт сард ногдох': 'law231',
        'Хуулийн 23.1-т заасан хөнгөлөлт нийт': 'law231_totl',
        'Хуулийн 7,1-д заасан орлогод ногдуулсан хөнгөлөлтийн дараах татварын дүн': 'aftr_tax_amnt',
        'Хуулийн 7.1.6-д заасан орлогод ногдуулсан дүн': 'law716_amnt',
        'Шууд бус орлогод ногдуулсан албан татвар': 'indirect_income_tax_amnt',
        'Нийт суутгуулсан албан татварын дүн': 'totl_amnt',
      },
      filters: {
        // salr_dt: [],
        // insr_type_nm: [],
        lastname: [],
				firstname: [],
				reg_num: [],
				// socl_insr_bk_no: [],
        // hlth_insr_bk_no: [],
				position: [],
				work_days: [],
				work_hours: [],
				worked_days: [],
				worked_hours: [],
				basc_salary: [],
				addn_salary: [],
				totl_salary: [],
				othr_addn_amnt: [],
				estm_salary: [],
				socl_insr_empl_amnt: [],
				socl_insr_rate_empl: [],
				psnl_inco_tax_amnt: [],
				psnl_inco_tax_rate: [],
				deduction1: [],
				deduction2: [],
				totl_deduction_amnt: [],
				hand_pay_salary: [],
        tax_credit: [],
        psnl_inco_tax_exempt_amnt: [],
        org_socl_insr_amnt: [],
        org_socl_insr_rate: [],
        signature: [],
			},
      gridRows: [],
      empls: [],
      taxinfos: [],
      editedIndex: -1,
      editedItem: {
        salr_dt: new Date().toISOString().substr(0, 7),
        notes: '',
        insr_type_nm: [],
        lastname: '',
				firstname: '',
				reg_num: '',
				socl_insr_bk_no: '',
        hlth_insr_bk_no: '',
				position: '',
				work_days: '22',
				work_hours: '',
				worked_days: '22',
				worked_hours: '',
				basc_salary: '',
				addn_salary: '',
				totl_salary: '',
				othr_addn_amnt: '',
				estm_salary: '',
				socl_insr_empl_amnt: '',
        socl_insr_rate_empl: '',
				psnl_inco_tax_amnt: '',
        psnl_inco_tax_rate: '',
				deduction1: '',
				deduction2: '',
				totl_deduction_amnt: '',
				hand_pay_salary: '',
				tax_credit: '0',
				psnl_inco_tax_exempt_amnt: '',
        signature: '',
        org_id: '',
        empl_id: '',
        org_socl_insr_amnt: '',
        org_socl_insr_rate: '',
      },
      defaultItem: {
        salr_dt: new Date().toISOString().substr(0, 7),
        notes: '',
        lastname: '',
				firstname: '',
				reg_num: '',
				socl_insr_bk_no: '',
        hlth_insr_bk_no: '',
				position: '',
				work_days: '22',
				work_hours: '',
				worked_days: '22',
				worked_hours: '',
				basc_salary: '',
				addn_salary: '',
				totl_salary: '',
				othr_addn_amnt: '',
				estm_salary: '',
				socl_insr_empl_amnt: '',
        socl_insr_rate_empl: '',
				psnl_inco_tax_amnt: '',
        psnl_inco_tax_rate: '',
				deduction1: '',
				deduction2: '',
				totl_deduction_amnt: '',
				hand_pay_salary: '',
				tax_credit: '0',
				psnl_inco_tax_exempt_amnt: '',
        signature: '',
        org_id: '',
        empl_id: '',
        org_socl_insr_amnt: '',
        org_socl_insr_rate: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
      filteredGridRows() {
        return this.gridRows.filter((i) => {
          return !this.editedItem.salr_dt || (i.salr_dt === this.editedItem.salr_dt);
        }).filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
				// return this.gridRows.filter((d) => {
				// 	return Object.keys(this.filters).every((f) => {
				// 		return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
				// 	});
				// });
			},
      tt11GridRows() {
        let data2 = [];
        let result = [];
        this.gridRows.reduce(function(res, value) {
          if (!res[value.reg_num]) {
            res[value.reg_num] = { reg_num: value.reg_num, lastname: value.lastname, firstname: value.firstname, estm_salary: 0, notes: value.notes,
                                    socl_insr_empl_amnt: 0, psnl_inco_tax_amnt: 0, psnl_inco_tax_exempt_amnt: 0, tax_credit: 0, months: 0, socl_insr_rate_empl: 0  };
            result.push(res[value.reg_num])
          }
          res[value.reg_num].estm_salary += value.estm_salary;
          res[value.reg_num].socl_insr_empl_amnt += value.socl_insr_empl_amnt;
          res[value.reg_num].psnl_inco_tax_amnt += value.psnl_inco_tax_amnt;
          res[value.reg_num].psnl_inco_tax_exempt_amnt += value.psnl_inco_tax_exempt_amnt;
          res[value.reg_num].tax_credit += value.tax_credit;
          res[value.reg_num].socl_insr_rate_empl += value.socl_insr_rate_empl;
          // edited by 23.01.25 (jiremsen/huuhdee asarch bgaa eej deer ajillasan sar nemegdeed bga)
          if (value.estm_salary > 0) {
            res[value.reg_num].months += 1;
          }
          return res;
        }, {});

        result.forEach(function (e) {
          let dV = (e.estm_salary-e.socl_insr_empl_amnt) / e.months
          // edited by 23.01.25 (tatvariin hongololt baih esgui type code 40)
          let dVal = e.notes == 501006 || e.notes == 501012 || e.notes == 501010 || e.notes == 501008 || e.notes == 501218 || e.notes == 501220 || e.notes == 501216 || e.notes == 501217 ? 0 : dV <=500000 ? 240000/12 : dV <= 1000000 ? 216000/12 : dV <= 1500000 ? 192000/12 : dV <= 2000000 ? 14000 : dV<=2500000 ? 144000/12 : dV<=3000000 ? 120000/12 : 0
          //let dVal = dV <=500000 ? 240000/12 : dV <= 1000000 ? 216000/12 : dV <= 1500000 ? 192000/12 : dV <= 2000000 ? 14000 : dV<=2500000 ? 144000/12 : dV<=3000000 ? 120000/12 : 0

          // let dval = var1 > 3000000 ? 0 : (var1 <= 500000 ? 20000 : (var1 <= 1000000 ? 216000/12 : (var1 <= 1500000 ? 192000/12 : (var1 <= 2000000 ? 14000 : (var1 <= 2500000 ? 144000/12 : (var1 <= 3000000 ? 120000/12 : 0))))))
          // let res = this.editedItem.notes == 501006 || this.editedItem.notes == 501008 ? 0 : this.editedItem.estm_salary > 0 ? (cond1 > 0 ? dval : dval + cond1) : 0
          // this.editedItem.tax_credit = !res ? 0 : common.moneyFormat2(res)

          // let ids = { tin: '', reg_num: e.reg_num, lastname: e.lastname, firstname: e.firstname, law711: common.moneyFormat(e.estm_salary),
          //             law712: '', law716: '', totl: common.moneyFormat(e.estm_salary), tax_rate: common.moneyFormatNum((e.socl_insr_rate_empl / e.months) / 100, 3),
          //             tax_amnt: common.moneyFormat(e.socl_insr_empl_amnt), tax_income: common.moneyFormat(e.estm_salary-e.socl_insr_empl_amnt),
          //             totl_tax_amnt: common.moneyFormat((e.estm_salary-e.socl_insr_empl_amnt)*0.1), month: e.months, law231: common.moneyFormat(dVal), law231_totl: common.moneyFormat(e.tax_credit),
          //             aftr_tax_amnt: common.moneyFormat((e.psnl_inco_tax_amnt-e.tax_credit) > 0 ? (e.psnl_inco_tax_amnt-e.tax_credit) : 0), law716_amnt: '', totl_amnt: common.moneyFormat((e.psnl_inco_tax_amnt-e.tax_credit) > 0 ? (e.psnl_inco_tax_amnt-e.tax_credit) : 0)
          //           };
          // 2023.01.18 changed by request (law231_totl = month * law231)
          let ids = { tin: '', reg_num: e.reg_num, lastname: e.lastname, firstname: e.firstname, law711: e.estm_salary,
                      law712: '', law716: '', totl: e.estm_salary, tax_rate: common.moneyFormatNum((e.socl_insr_rate_empl / e.months), 3),
                      tax_amnt: e.socl_insr_empl_amnt, tax_income: e.estm_salary-e.socl_insr_empl_amnt,
                      totl_tax_amnt: (e.estm_salary-e.socl_insr_empl_amnt)*0.1, month: e.months,
                      // edited by 23.01.28 (hongololt)
                      law231: ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) < (e.months*dVal) ? ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) / e.months : dVal,
                      // edited by 23.02.01 (bodoltii butsaad huuchnaar ni bolgov)
                      law231_totl: e.tax_credit,
                      // law231_totl: ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) < (e.months*dVal) ? (e.estm_salary-e.socl_insr_empl_amnt)*0.1 : e.months*dVal,
                      // edited by 23.01.26 (after_tax_amnt buruu garaad bn)
                      // aftr_tax_amnt: ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) < (e.months*dVal) ? 0 : ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) - (e.months*dVal),
                      // law716_amnt: '', totl_amnt: ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) < (e.months*dVal) ? 0 : ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) - (e.months*dVal)
                      aftr_tax_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0, law716_amnt: '', totl_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0
                    };
          // let ids = { tin: '', reg_num: e.reg_num, lastname: e.lastname, firstname: e.firstname, law711: e.estm_salary,
          //             law712: '', law716: '', totl: e.estm_salary, tax_rate: common.moneyFormatNum((e.socl_insr_rate_empl / e.months), 3),
          //             tax_amnt: e.socl_insr_empl_amnt, tax_income: e.estm_salary-e.socl_insr_empl_amnt,
          //             totl_tax_amnt: (e.estm_salary-e.socl_insr_empl_amnt)*0.1, month: e.months, law231: dVal, law231_totl: e.tax_credit,
          //             aftr_tax_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0, law716_amnt: '', totl_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0
          //           };
          data2.push(ids);
        });
        return data2;
      },
      tt11GridRows2() {
        let data2 = [];
        let result = [];
        this.gridRows.reduce(function(res, value) {
          if (!res[value.reg_num]) {
            res[value.reg_num] = { reg_num: value.reg_num, lastname: value.lastname, firstname: value.firstname, estm_salary: 0, notes: value.notes,
                                    socl_insr_empl_amnt: 0, psnl_inco_tax_amnt: 0, psnl_inco_tax_exempt_amnt: 0, tax_credit: 0, months: 0, socl_insr_rate_empl: 0  };
            result.push(res[value.reg_num])
          }
          res[value.reg_num].estm_salary += value.estm_salary;
          res[value.reg_num].socl_insr_empl_amnt += value.socl_insr_empl_amnt;
          res[value.reg_num].psnl_inco_tax_amnt += value.psnl_inco_tax_amnt;
          res[value.reg_num].psnl_inco_tax_exempt_amnt += value.psnl_inco_tax_exempt_amnt;
          res[value.reg_num].tax_credit += value.tax_credit;
          res[value.reg_num].socl_insr_rate_empl += value.socl_insr_rate_empl;
          // edited by 23.01.25 (jiremsen/huuhdee asarch bgaa eej deer ajillasan sar nemegdeed bga)
          if (value.estm_salary > 0) {
            res[value.reg_num].months += 1;
          }
          return res;
        }, {});

        result.forEach(function (e) {
          let dV = (e.estm_salary-e.socl_insr_empl_amnt) / e.months
          // edited by 23.01.25 (tatvariin hongololt baih esgui type code 40)
          let dVal = e.notes == 501006 || e.notes == 501012 || e.notes == 501010 || e.notes == 501008 || e.notes == 501218 || e.notes == 501220 || e.notes == 501216 || e.notes == 501217 ? 0 : dV <=500000 ? 240000/12 : dV <= 1000000 ? 216000/12 : dV <= 1500000 ? 192000/12 : dV <= 2000000 ? 14000 : dV<=2500000 ? 144000/12 : dV<=3000000 ? 120000/12 : 0
      
          // 2023.01.18 changed by request (law231_totl = month * law231)
          let ids = { tin: e.reg_num, lastname: e.lastname, firstname: e.firstname, law711: e.estm_salary,
                      law712: '', law716: '', totl: e.estm_salary,
                      tax_rate: common.moneyFormatNum((e.socl_insr_rate_empl / e.months), 3),
                      tax_amnt: e.socl_insr_empl_amnt, tax_amnt716: '',
                      tax_income: e.estm_salary-e.socl_insr_empl_amnt,
                      income_type: '', income: '',
                      totl_tax_amnt: (e.estm_salary-e.socl_insr_empl_amnt)*0.1,
                      stage: (e.estm_salary-e.socl_insr_empl_amnt) < 120000001 ? '1' : (e.estm_salary-e.socl_insr_empl_amnt) < 180000001 ? '2' : '3',
                      //=IF(A2=0,"",IF((D2+E2-I2)<120000001,(D2+E2-I2)*0.1,IF((D2+E2-I2)<180000001,12000000+((D2+E2-I2)-120000000)*0.15,21000000+((D2+E2-I2)-180000000)*0.2)))
                      tax_711_715_717: (e.estm_salary-e.socl_insr_empl_amnt) < 120000001 ? (e.estm_salary-e.socl_insr_empl_amnt)*0.1 : (e.estm_salary-e.socl_insr_empl_amnt) < 180000001 ? (12000000+(e.estm_salary-e.socl_insr_empl_amnt-120000000)*0.15) : (21000000+(e.estm_salary-e.socl_insr_empl_amnt-180000000)*0.2),
                      month: e.months,
                      // edited by 23.01.28 (hongololt)
                      law231: ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) < (e.months*dVal) ? ((e.estm_salary-e.socl_insr_empl_amnt)*0.1) / e.months : dVal,
                      // edited by 23.02.01 (bodoltii butsaad huuchnaar ni bolgov)
                      law231_totl: e.tax_credit,
                      aftr_tax_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0, law716_amnt: '',
                      indirect_income_tax_amnt: '',
                      totl_amnt: e.psnl_inco_tax_amnt > 0 ? e.psnl_inco_tax_amnt : 0
                    };
          data2.push(ids);
        });
        return data2;
      },
      totlSalary () {
        this.editedItem.totl_salary = Number(this.editedItem.basc_salary) + Number(this.editedItem.addn_salary)
        return this.editedItem.totl_salary
      },
      estmSalary () {
        this.editedItem.estm_salary = this.editedItem.notes ? (this.editedItem.notes != '501003' && this.editedItem.notes != '501004' && this.editedItem.notes != '501007' && this.editedItem.notes != '501205' && this.editedItem.notes != '501208' && this.editedItem.notes != '501209' && this.editedItem.notes != '501210' && this.editedItem.notes != '501213' && this.editedItem.notes != '501216' && this.editedItem.notes != '501217' ? Number(this.editedItem.totl_salary) * Number(this.editedItem.worked_hours) / Number(this.editedItem.work_hours) + Number(this.editedItem.othr_addn_amnt) : 0) : 0
        return this.editedItem.estm_salary
      },
      soclInsrEmplAmnt () {
        // console.log(JSON.stringify(this.taxinfos))
        // return 0
        this.editedItem.socl_insr_empl_amnt = this.editedItem.estm_salary > this.selectedTax.min_wage_amnt * 10 ? this.selectedTax.min_wage_amnt * 10 * this.editedItem.socl_insr_rate_empl / 100 : this.editedItem.estm_salary * this.editedItem.socl_insr_rate_empl / 100
        return this.editedItem.socl_insr_empl_amnt
      },
      soclInsrEmplRate () {
        return this.selectedTax.socl_insr_rate
      },
      psnlIncoTaxAmnt () {
        // 24.04.16 rate 0 ued 2k utga buruu zoogdood bsn.
        this.editedItem.psnl_inco_tax_amnt = (this.editedItem.estm_salary - this.editedItem.socl_insr_empl_amnt) * this.editedItem.psnl_inco_tax_rate / 100 - this.editedItem.tax_credit < 0 ? 0 : (this.editedItem.estm_salary == 0 ? 0 : (this.editedItem.estm_salary - this.editedItem.socl_insr_empl_amnt) * this.editedItem.psnl_inco_tax_rate / 100 - this.editedItem.tax_credit - this.editedItem.psnl_inco_tax_exempt_amnt)
        // this.editedItem.psnl_inco_tax_amnt = (this.editedItem.estm_salary - this.editedItem.socl_insr_empl_amnt) * this.editedItem.psnl_inco_tax_rate / 100 - this.editedItem.tax_credit < 0 ? 0 : (this.editedItem.estm_salary == 0 ? 0 : (this.editedItem.estm_salary - this.editedItem.socl_insr_empl_amnt) * this.editedItem.psnl_inco_tax_rate / 100 - this.editedItem.tax_credit - this.editedItem.psnl_inco_tax_exempt_amnt)
        // return this.selectedTax.psnl_inco_tax_rate <= 0 ? 0 : this.editedItem.psnl_inco_tax_amnt
        return this.editedItem.psnl_inco_tax_amnt
      },
      psnlIncoTaxRate () {
        return this.selectedTax.psnl_inco_tax_rate
      },
      totlDeductionAmnt () {
        this.editedItem.totl_deduction_amnt = Number(this.editedItem.socl_insr_empl_amnt) + Number(this.editedItem.psnl_inco_tax_amnt) + Number(this.editedItem.deduction1) + Number(this.editedItem.deduction2)
        return this.editedItem.totl_deduction_amnt
      },
      handPaySalary () {
        this.editedItem.hand_pay_salary = Number(this.editedItem.estm_salary) - Number(this.editedItem.totl_deduction_amnt)
        return this.editedItem.hand_pay_salary
      },
      taxCredit () {
        // if (this.editedIndex > -1) return
        let var1 = Number(this.editedItem.estm_salary) - Number(this.editedItem.socl_insr_empl_amnt)
        let cond1 = this.editedItem.estm_salary == 0 ? 0 : ((var1*Number(this.editedItem.psnl_inco_tax_rate)/100)-20000)
        // =IF(AH32<0,IF((R32-S32)<=500000,20000,IF((R32-S32)<=1000000,216000/12,IF((R32-S32)<=1500000,192000/12,IF((R32-S32)<=2000000,14000,IF((R32-S32)<=2500000,144000/12,IF((R32-S32)<=3000000,120000/12,0))))))+AH32,IF((R32-S32)<=500000,20000,IF((R32-S32)<=1000000,216000/12,IF((R32-S32)<=1500000,192000/12,IF((R32-S32)<=2000000,14000,IF((R32-S32)<=2500000,144000/12,IF((R32-S32)<=3000000,120000/12,0)))))))
        let dval = var1 > 3000000 ? 0 : (var1 <= 500000 ? 20000 : (var1 <= 1000000 ? 216000/12 : (var1 <= 1500000 ? 192000/12 : (var1 <= 2000000 ? 14000 : (var1 <= 2500000 ? 144000/12 : (var1 <= 3000000 ? 120000/12 : 0))))))
        // this.editedItem.tax_credit = this.editedItem.notes == '501006' ? 0 : cond1 > 0 ? (var1 <= 500000 ? 20000 : (var1 <= 1000000 ? 216000/12 : (var1 <= 1500000 ? 192000/12 : (var1 <= 2000000 ? 14000 : (var1 <= 2500000 ? 144000/12 : (var1 <= 3000000 ? 120000/12 : 0)))))) : 0;
        // 24.04.16 - new code added
        // let res = this.editedItem.notes == 501006 || this.editedItem.notes == 501012 || this.editedItem.notes == 501010 || this.editedItem.notes == 501008 ? 0 : this.editedItem.estm_salary > 0 ? (cond1 > 0 ? dval : dval + cond1) : 0
        let res = this.editedItem.notes == 501006 || this.editedItem.notes == 501012 || this.editedItem.notes == 501010 || this.editedItem.notes == 501008 || this.editedItem.notes == 501218 || this.editedItem.notes == 501220 || this.editedItem.notes == 501216 || this.editedItem.notes == 501217 ? 0 : this.editedItem.estm_salary > 0 ? (cond1 > 0 ? dval : dval + cond1) : 0
        
        this.editedItem.tax_credit = !res ? 0 : (res < 0 ? 0 : common.moneyFormat2(res))
        return this.editedItem.tax_credit
      },
      soclInsrOrgAmnt () {
        // alert(this.taxinfos.filter(x=> x.notes === this.editedItem.notes))
        this.editedItem.org_socl_insr_amnt = this.editedItem.notes ? (this.editedItem.notes != '501003' && this.editedItem.notes != '501004' && this.editedItem.notes != '501007' && this.editedItem.notes != '501205' && this.editedItem.notes != '501208' && this.editedItem.notes != '501209' && this.editedItem.notes != '501210' && this.editedItem.notes != '501213' && this.editedItem.notes != '501216' && this.editedItem.notes != '501217' ? Number(this.editedItem.estm_salary) : Number(this.editedItem.basc_salary)) * Number(this.editedItem.org_socl_insr_rate)/100 : 0
        return this.editedItem.org_socl_insr_amnt
      },
      soclInsrOrgRate () {
        // return this.editedItem.notes ? this.taxinfos.filter(x=> x.notes === this.editedItem.notes)[0].org_socl_insr_rate : ''
        return this.editedItem.notes ? this.selectedTax.org_socl_insr_rate : ''
      },
      workDays () {
        this.editedItem.work_hours = this.editedItem.work_days ? Number(this.editedItem.work_days) * 8 : 0
        // return this.editedItem.work_hours
      },
      workedDays () {
        this.editedItem.worked_hours = this.editedItem.worked_days ? Number(this.editedItem.worked_days) * 8 : 0
        // return this.editedItem.worked_hours
      }
      
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.getEmployerInfo()
      this.getOptions()
      this.getOrgInfo()
      this.initialize()
    },

    methods: {
      initialize () {
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let year = user_dt[0].substr(0, 4)
        const params = {
          org_id: { toJSON: () => JSON.parse(sessionStorage.getItem('user_org')).id },
          s_dt: user_dt[0].substr(0, 7),
          f_dt: user_dt[1].substr(0, 7)
        };
        axios.get('salary/empsalr', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          })
          
        axios.get('commoncode/parent_code/501/'+year)
          .then(response => {
            this.insrType = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      // checkData () {
      //   if (!this.filteredGridRows) {
      //     alert('No data to export!');
      //     return false;
      //   }
      //   return true;
      // },

      required(value) {
        if (value == undefined || value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      getEmployerInfo () {
        const params = {
          org_id: { toJSON: () => JSON.parse(sessionStorage.getItem('user_org')).id }
        };
        axios.get('employers', { params })
          .then(response => {
            this.empls = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      getOptions () {
        axios.get('taxinfos')
          .then(response => {
            this.taxinfos = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      selectedEmployer(empl) {
        // console.log(JSON.stringify(empl))
        this.editedItem.empl_id = empl.id
        this.editedItem.notes = empl.notes
        this.editedItem.insr_type_nm = this.insrType.filter(x=> x.code === empl.notes)[0]
        // this.editedItem.work_days = empl.notes != 501003 && empl.notes != 501004 && empl.notes != 501007 ? '22' : '0'
        this.editedItem.work_days = empl.notes != 501003 && empl.notes != 501004 && empl.notes != 501007 && empl.notes != 501205 && empl.notes != 501208 && empl.notes != 501209 && empl.notes != 501210 && empl.notes != 501213 && empl.notes != 501216 && empl.notes != 501217 ? '22' : '0'
        // this.editedItem.worked_days = empl.notes != 501003 && empl.notes != 501004 && empl.notes != 501007 ? '22' : '0'
        this.editedItem.worked_days = empl.notes != 501003 && empl.notes != 501004 && empl.notes != 501007 && empl.notes != 501205 && empl.notes != 501208 && empl.notes != 501209 && empl.notes != 501210 && empl.notes != 501213 && empl.notes != 501216 && empl.notes != 501217 ? '22' : '0'
        this.editedItem.lastname = empl.lastname
        this.editedItem.firstname = empl.firstname
        this.editedItem.reg_num = empl.reg_num
        this.editedItem.position = empl.position
        this.editedItem.basc_salary = empl.salary
        this.editedItem.socl_insr_bk_no = empl.socl_insr_bk_no
        this.editedItem.hlth_insr_bk_no = empl.hlth_insr_bk_no
        this.selectedTax = this.taxinfos.filter(x=> x.notes === empl.notes && this.editedItem.salr_dt.substr(0,4) >= x.cvge_strt_dt.substr(0,4) && this.editedItem.salr_dt.substr(0,4) <= x.cvge_end_dt.substr(0,4))[0]
        this.editedItem.socl_insr_rate_empl = this.selectedTax.socl_insr_rate;
        this.editedItem.psnl_inco_tax_rate = this.selectedTax.psnl_inco_tax_rate;
        this.editedItem.org_socl_insr_rate = this.selectedTax.org_socl_insr_rate;
        
        this.dialog2 = false
      },

      changeMonth(dt) {
        // alert(dt)
      },

      formatPrice(value) {
        // let val = value ? Math.round(value) : 0 //(value/1).toFixed(2).replace('. ', ',')
        // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      columnValueList(val) {
				// return this.gridRows.map((d) => d[val]);
        return this.gridRows.map((d) => d[val] ? d[val] : '-');
			},


      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.selectedTax = this.taxinfos.filter(x=> x.notes === this.editedItem.notes && this.editedItem.salr_dt.substr(0,4) >= x.cvge_strt_dt.substr(0,4) && this.editedItem.salr_dt.substr(0,4) <= x.cvge_end_dt.substr(0,4))[0]
        this.editedItem.insr_type_nm = this.insrType.filter(x=> x.code === this.editedItem.notes)[0]
        this.dialog = true
        this.editedItem.tax_credit = item.tax_credit
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        this.selected.forEach(function (e) {
            ids.push(e.id);
        });

        axios.delete('salary/' + ids)
          .then(response => {
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.reloadMain()
            this.closeDelete()
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          let selDt = this.editedItem.salr_dt
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.salr_dt = selDt
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          let selDt = this.editedItem.salr_dt
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.salr_dt = selDt
          this.editedIndex = -1
        })
      },

      resetForm() {
        // this.$refs.form.reset()
        let selDt = this.editedItem.salr_dt
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.salr_dt = selDt
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        // this.editedItem.socl_insr_rate_empl = this.selectedTax.socl_insr_rate;
        // this.editedItem.psnl_inco_tax_rate = this.selectedTax.psnl_inco_tax_rate;
        // this.editedItem.org_socl_insr_amnt = this.editedItem.estm_salary * this.selectedTax.org_socl_insr_rate / 100;
        // this.editedItem.org_socl_insr_rate = this.selectedTax.org_socl_insr_rate;
        if (this.editedIndex > -1) {
          axios.put('salary/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай засварлалаа.');
              this.reloadMain()
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Мэдээлэл засварлахад алдаа гарлаа.');
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
          this.close()
        } else {
          axios.post('salary/store', this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай орууллаа.');
              this.reloadMain()
              this.resetForm()
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              alert('Мэдээлэл оруулахад алдаа гарлаа.');
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        // this.close()
      },

      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => a + (b[key] || 0), 0)
      },
      
      reloadMain() {
        this.initialize();
      },

      wholePrint() {
        if (this.filteredGridRows.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        var org = this.orgs.filter(x=> x.id==org_id)[0]

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text(org_nm, 0.2, 0.2);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text(date_range.join(' ~ '), 10.2, 0.2);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Аж ахуйн нэгжийн нэр)', 0.2, 0.3,);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Тайлант үе)', 10.2, 0.3);
        // create a line under heading 
        doc.setLineWidth(0.001).line(0.2, 0.35, 11.5, 0.35);
        // set selected account number and customer name
        doc.setFont("Arial-Mon", "bold").setFontSize(11).text('Цалингийн хүснэгт (' + this.editedItem.salr_dt + ')', 0.2, 0.6);
        
        // set headers and data for autoTable
        // const headers2 = [
        //   ['Овог', 'Нэр', 'Татвар төлөгчийн дугаар', 'Албан тушаал', 'Ажиллах өдөр', 'Ажиллах цаг', 'Ажилласан өдөр', 'Ажилласан цаг',
        //   'Үндсэн цалин', 'Нэмэгдэл цалин', 'Нийт цалин', 'Бусад нэмэгдэл/ээлжийн амралт/', 'Олгохоор тооцсон цалин', 'НДШ ажилтан', 'НДШ хувь', 'ХХОАТ', 'ХХОАТ хувь',
        //   'Суутгал 1', 'Суутгал 2', 'Суутгалын дүн', 'Гарт олгох цалин', 'Татварын хөнгөлөлт', 'Чөлөөлөгдөх ХХОАТ-н дүн', 'НДШ байгууллага', 'НДШ байгууллага хувь']
        // ];
        const headers2 = [
          ['Овог', 'Нэр', 'Татвар төлөгчийн дугаар', 'Албан тушаал', 'Ажиллах өдөр', 'Ажиллах цаг', 'Ажилласан өдөр', 'Ажилласан цаг',
          'Үндсэн цалин', 'Нэмэгдэл цалин', 'Нийт цалин', 'Бусад нэмэгдэл/ээлжийн амралт/', 'Олгохоор тооцсон цалин', 'НДШ ажилтан', 'НДШ хувь', 'ХХОАТ', 'ХХОАТ хувь',
          'Суутгал 1', 'Суутгал 2', 'Суутгалын дүн', 'Гарт олгох цалин']
        ];

        let data2 = [];
        let result = this.filteredGridRows;
        
        result.forEach(function (e) {
          var ids = [];
          ids.push(e.lastname);
          ids.push(e.firstname);
          ids.push(e.reg_num);
          ids.push(e.position);
          ids.push(e.work_days);
          ids.push(e.work_hours);
          ids.push(e.worked_days);
          ids.push(e.worked_hours);
          ids.push(common.moneyFormat(e.basc_salary));
          ids.push(common.moneyFormat(e.addn_salary));
          ids.push(common.moneyFormat(e.totl_salary));
          ids.push(common.moneyFormat(e.othr_addn_amnt));
          ids.push(common.moneyFormat(e.estm_salary));
          ids.push(common.moneyFormat(e.socl_insr_empl_amnt));
          ids.push(e.socl_insr_rate_empl);
          ids.push(common.moneyFormat(e.psnl_inco_tax_amnt));
          ids.push(e.psnl_inco_tax_rate);
          ids.push(common.moneyFormat(e.deduction1));
          ids.push(common.moneyFormat(e.deduction2));
          ids.push(common.moneyFormat(e.totl_deduction_amnt));
          ids.push(common.moneyFormat(e.hand_pay_salary));
          // ids.push(common.moneyFormat(e.tax_credit));
          // ids.push(common.moneyFormat(e.psnl_inco_tax_exempt_amnt));
          // ids.push(common.moneyFormat(e.org_socl_insr_amnt));
          // ids.push(e.org_socl_insr_rate);
          data2.push(ids);
        });
        var fval2 = [];
        fval2.push(null);
        fval2.push('нийт дүн');
        fval2.push(null);
        fval2.push(null);
        fval2.push(null);
        fval2.push(null);
        fval2.push(null);
        fval2.push(null);
        fval2.push(common.moneyFormat(this.sumField('basc_salary')));
        fval2.push(common.moneyFormat(this.sumField('addn_salary')));
        fval2.push(common.moneyFormat(this.sumField('totl_salary')));
        fval2.push(common.moneyFormat(this.sumField('othr_addn_amnt')));
        fval2.push(common.moneyFormat(this.sumField('estm_salary')));
        fval2.push(common.moneyFormat(this.sumField('socl_insr_empl_amnt')));
        fval2.push(null);
        fval2.push(common.moneyFormat(this.sumField('psnl_inco_tax_amnt')));
        fval2.push(null);
        fval2.push(common.moneyFormat(this.sumField('deduction1')));
        fval2.push(common.moneyFormat(this.sumField('deduction2')));
        fval2.push(common.moneyFormat(this.sumField('totl_deduction_amnt')));
        fval2.push(common.moneyFormat(this.sumField('hand_pay_salary')));
        data2.push(fval2);

        if (data2.length == 0) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, body: data2, margin: { left: 0.2, right: 0.2, top: 0.65 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 6} })
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 4.5, finalY+0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 4.5, finalY+0.7);
        
        // saving file
        doc.save(`salary_report.pdf`);
      },

    },
  }
</script>