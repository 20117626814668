<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="acct"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Гүйлгээ Баланс</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-switch
          v-model="isActive"
          label="Идэвхитэй данс"
          color="success"
          value="true"
          hide-details
        ></v-switch>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Print (start) -->
        <v-btn
          color="warning lighten-2"
          dark
          class="mb-2 mr-2"
          @click="wholePrint()"
        >
          PDF <i class="mdi mdi-printer" aria-hidden="true"></i>
        </v-btn>
        <!-- Print (end) -->

        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows"
          :fields="export_fields"
          :labels="export_labels"
          name="balance.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-2">
            Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogUpdateAmnt"
          width="650"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="green lighten-2"
            dark
            class="mb-2 mr-2"
            v-if="!readonly_check"
            @click="updateAmnt"
          >
            Э/Ү шинэчлэх
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline green lighten-2"
              primary-title
            >
              Тухайн оны дүнгээр эхний үлдэгдэл шинэчлэх үү?
            </v-card-title>
    
            <v-card-text>
              <br>Тухайн оны дансны үлдэгдэл дараа оны эхний үлдэгдэл болон хуулагдах болно.<br>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="amntYear1"
                  label="Дансны үлдэгдэлийн харгалзах он"
                  :rules="[required]"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="amntYear2"
                  label="Эхний үлдэгдэл болон хуулагдах он"
                  :rules="[required]"
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <br>Дээрх утгыг шалгана уу.
              
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeUpdateAmnt"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="updateAmntConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog"
          max-width="1300px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-2"
              dark
              class="mb-2"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              Дансны удирдлага
              <!-- <v-icon small class="ml-1">mdi-plus-thick</v-icon> -->
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline blue lighten-2" primary-title>
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              Дансны удирдлага
              <v-spacer></v-spacer>
              <v-btn @click="reloadMain">
                Мэдээлэл шинэчлэх
              </v-btn>
            </v-card-title>
            <v-container fluid>
              <v-row>
                <v-col cols="8">
                  <div class="pa-1">
                    <v-data-table
                      v-model="selected"
                      :headers="acctsHeaders"
                      :items="filteredAcctsGridRows"
                      :search="search"
                      sort-by="notes"
                      class="elevation-1"
                      dense
                      :loading="loadTable"
                      loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
                      no-data-text="Мэдээлэл байхгүй байна."
                      no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                        >
                          <v-toolbar-title>Дансны жагсаалт</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-col cols="12" sm="7">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Хайлт"
                              rounded
                              filled
                              background-color="blue lighten-5"
                              clearable
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-toolbar>
                      </template>
                  
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="acctsEditItem(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          @click="acctsDeleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <template v-slot:item.no = "{ index }">
                        {{ index + 1 }}
                      </template>
                      <template v-slot:no-data>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
                <v-col>
                  <v-card width="340">
                    <v-card-title class="headline green lighten-5">
                      <h5>Бүртгэл</h5>
                    </v-card-title>
                    <div class="pa-1">
                      <v-form ref="form">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="acctsEditedItem.name"
                                label="Дансны нэр *"
                                :rules="[required]"
                                outlined
                                hide-details
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="acctsEditedItem.notes"
                                label="Дансны дугаар *"
                                :rules="[required]"
                                outlined
                                hide-details
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="acctsEditedItem.debit_amnt"
                                label="Дебит үлдэгдэл"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-text-field
                                v-model="acctsEditedItem.credit_amnt"
                                label="Кредит үлдэгдэл"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="acctsEditedItem.acct_type1"
                                label="Дансны төрөл-1 *"
                                :items="accts1"
                                item-text="comb_name"
                                :rules="[required]"
                                outlined
                                hide-details
                                dense
                                return-object
                                no-data-text="Тохирох утга олдсонгүй!"
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="acctsEditedItem.acct_type2"
                                label="Дансны төрөл-2 (МГ тайлан)*"
                                :items="accts2"
                                item-text="comb_name"
                                :rules="[required]"
                                outlined
                                hide-details
                                dense
                                return-object
                                no-data-text="Тохирох утга олдсонгүй!"
                              >
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <h4 style="color:red">* Заавал бөглөх талбар</h4>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="red lighten-3"
                          @click="resetAcctsForm"
                        >
                          Reset
                        </v-btn>
                        <v-btn
                          :right="true"
                          :absolute="true"
                          color="green lighten-3"
                          @click="saveAccts"

                        >
                          Хадгалах
                        </v-btn>
                      </v-card-actions>
                      </v-form>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Тухайн мэдээллийг устгахад итгэлтэй байна уу?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Цуцлах</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Устгах</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>
 
    <template v-slot:item.bfr_debit_amnt="{ item }">
      <span>{{formatPrice(item.bfr_debit_amnt)}}</span>
    </template>
    <template v-slot:item.bfr_credit_amnt="{ item }">
      <span>{{formatPrice(item.bfr_credit_amnt)}}</span>
    </template>
    <template v-slot:item.cur_debit_amnt="{ item }">
      <span>{{formatPrice(item.cur_debit_amnt)}}</span>
    </template>
    <template v-slot:item.cur_credit_amnt="{ item }">
      <span>{{formatPrice(item.cur_credit_amnt)}}</span>
    </template>
    <template v-slot:item.lst_debit_amnt="{ item }">
      <span>{{formatPrice(item.lst_debit_amnt)}}</span>
    </template>
    <template v-slot:item.lst_credit_amnt="{ item }">
      <span>{{formatPrice(item.lst_credit_amnt)}}</span>
    </template>

    <template v-slot:body.append>
      <tr class="bold--text">
        <th />
        <th class="text-left">НИЙТ ДҮН</th>
        <th />
        <th class="text-right">{{ formatPrice(sumField('bfr_debit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('bfr_credit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('cur_debit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('cur_credit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('lst_debit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('lst_credit_amnt')) }}</th>
      </tr>
    </template>

    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'
  // import { required, minLength, between } from 'vuelidate/lib/validators';

  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      footerProps: {'items-per-page-options': [10, 20, 50, 100, -1]},
      pagination: {
        sortBy: 'name'
      },
      dialog: false,
      dialogDelete: false,
      dialogUpdateAmnt: false,
      search: '',
      selected: [],
      accts1: [],
      accts2: [],
      amntYear1: '',
      amntYear2: '',
      orgs: [],
      loadTable: true,
      isActive: false,
      acctsHeaders: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Дансны нэр', value: 'name', divider: true, class: bgColorCode },
        { text: 'Дансны дугаар', value: 'notes', divider: true, class: bgColorCode },
        { text: 'Дебит үлдэгдэл', value: 'debit_amnt', divider: true, class: bgColorCode },
        { text: 'Кредит үлдэгдэл', value: 'credit_amnt', divider: true, class: bgColorCode },
        { text: 'Дансны төрөл-1', value: 'acct_type1_nm', divider: true, class: bgColorCode },
        { text: 'Дансны төрөл-2', value: 'acct_type2_nm', divider: true, class: bgColorCode },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, width: '1%' },
      ],
      acctsFilters: {
        name: [],
        notes: [],
			  acct_type1: [],
			  acct_type2: [],
			},
      acctsGridRows: [],
      acctsEditedIndex: -1,
      name: '',
      notes: '',
      acctsEditedItem: {
        name: '',
        notes: '',
        debit_amnt: '',
        credit_amnt: '',
        acct_type1: '',
			  acct_type2: '',
			  acct_type1_nm: '',
			  acct_type2_nm: '',
			},
      acctsDefaultItem: {
        name: '',
        notes: '',
        debit_amnt: '',
        credit_amnt: '',
        acct_type1: '',
			  acct_type2: '',
			  acct_type1_nm: '',
			  acct_type2_nm: '',
			},
      headers: [
        {
          text: '№',
          align: 'center',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Дансны нэр', value: 'name', divider: true, class: bgColorCode },
        { text: 'Дансны код', value: 'acct', divider: true, class: bgColorCode, align: 'center', width: '120px' },
        { text: 'Дебит эхний үлдэгдэл', value: 'bfr_debit_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Кредит эхний үлдэгдэл', value: 'bfr_credit_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Дебит гүйлгээ', value: 'cur_debit_amnt', divider: true, class: bgColorCode, align: 'right', width: '135px' },
        { text: 'Кредит гүйлгээ', value: 'cur_credit_amnt', divider: true, class: bgColorCode, align: 'right', width: '140px' },
        { text: 'Дебит эцсийн үлдэгдэл', value: 'lst_debit_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Кредит эцсийн үлдэгдэл', value: 'lst_credit_amnt', divider: false, class: bgColorCode, align: 'right', width: '120px' },
        // { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, align: 'center', width: '1%' },
      ],
      export_fields: [
        'no', 'name', 'acct', 'bfr_debit_amnt', 'bfr_credit_amnt',
        'cur_debit_amnt', 'cur_credit_amnt', 'lst_debit_amnt', 'lst_credit_amnt'
      ],
      export_labels: {
        no: '№',
        name: 'Дансны нэр',
        acct: 'Дансны код',
        bfr_debit_amnt: 'Дебит эхний үлдэгдэл',
        bfr_credit_amnt: 'Кредит эхний үлдэгдэл',
        cur_debit_amnt: 'Дебит гүйлгээ',
        cur_credit_amnt: 'Кредит гүйлгээ',
        lst_debit_amnt: 'Дебит эцсийн үлдэгдэл',
        lst_credit_amnt: 'Кредит эцсийн үлдэгдэл'
      },
      filters: {
        name: [],
				acct: [],
				bfr_debit_amnt: [],
				bfr_credit_amnt: [],
        cur_debit_amnt: [],
				cur_credit_amnt: [],
				lst_debit_amnt: [],
				lst_credit_amnt: [],
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        name: '',
				acct: '',
				bfr_debit_amnt: '',
				bfr_credit_amnt: '',
        cur_debit_amnt: '',
				cur_credit_amnt: '',
				lst_debit_amnt: '',
				lst_credit_amnt: '',
      },
      defaultItem: {
        name: '',
				acct: '',
				bfr_debit_amnt: '',
				bfr_credit_amnt: '',
        cur_debit_amnt: '',
				cur_credit_amnt: '',
				lst_debit_amnt: '',
				lst_credit_amnt: '',
      },
    }),

    // validations: {
    //   name: {
    //     required,
    //     minLength: minLength(6)
    //   },
    //   notes: {
    //     required,
    //     minLength: minLength(6)
    //   }
    // },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						// return (this.filters[f].length < 1 || this.filters[f].includes(d[f])) && this.isActive ? (d.cur_debit_amnt != null || d.cur_credit_amnt != null) : true;
						// return (this.filters[f].length < 1 || this.filters[f].includes(d[f])) && this.isActive ? (
            //   (d.bfr_debit_amnt != null && d.bfr_debit_amnt > 0) || (d.bfr_credit_amnt != null && d.bfr_credit_amnt > 0) ||
            //   (d.cur_debit_amnt != null && d.cur_debit_amnt > 0) || (d.cur_credit_amnt != null && d.cur_credit_amnt > 0) ||
            //   (d.lst_debit_amnt != null && d.lst_debit_amnt > 0) || (d.lst_credit_amnt != null && d.lst_credit_amnt > 0)) : true;
						return this.isActive ? (this.filters[f].length < 1 || this.filters[f].includes(d[f])) && (
              (d.bfr_debit_amnt != null && d.bfr_debit_amnt > 0) || (d.bfr_credit_amnt != null && d.bfr_credit_amnt > 0) ||
              (d.cur_debit_amnt != null && d.cur_debit_amnt > 0) || (d.cur_credit_amnt != null && d.cur_credit_amnt > 0) ||
              (d.lst_debit_amnt != null && d.lst_debit_amnt > 0) || (d.lst_credit_amnt != null && d.lst_credit_amnt > 0)) : (this.filters[f].length < 1 || this.filters[f].includes(d[f]));
					});
				});
        // let dd = this.gridRows.filter(d => {
				// 	return (d.bfr_debit_amnt != null || d.bfr_credit_amnt != null || d.cur_debit_amnt != null || d.cur_credit_amnt != null || d.lst_debit_amnt != null || d.lst_credit_amnt != null);
				// });
			},
      filteredAcctsGridRows() {
				return this.acctsGridRows.filter((d) => {
					return Object.keys(this.acctsFilters).every((f) => {
						return this.acctsFilters[f].length < 1 || this.acctsFilters[f].includes(d[f]);
					});
				});
			},
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Дансны нэр заавал бөглөнө.')
        !this.$v.name.minLength && errors.push('Дансны нэр хамгийн багадаа 6 тэмдэгт байна.')
        return errors
      },
      notesErrors () {
        const errors = []
        if (!this.$v.notes.$dirty) return errors
        !this.$v.notes.required && errors.push('Дансны дугаар заавал бөглөнө.')
        !this.$v.notes.minLength && errors.push('Дансны дугаар хамгийн багадаа 6 тоо байна.')
        return errors
      },
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        // this.$refs.form.reset()
        this.resetAcctsForm()
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogUpdateAmnt (val) {
        val || this.closeUpdateAmnt()
      },
    },

    created () {
      if (!sessionStorage.hasOwnProperty('user_org') || !sessionStorage.hasOwnProperty('user_dt')) {
        // alert('Аж ахуйн нэгж / Тайлант үе сонгогдоогүй байна.');
        this.loadTable = false;
        return false;
      }
      this.initialize()
      this.getOptions()
      this.getOrgInfo()
    },

    methods: {
      initialize () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('journal/balance', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          });
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      getOptions () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let year = user_dt[0].substr(0, 4)
        axios.get('commoncode/accts_type/201')
          .then(response => {
            this.accts1 = response.data;
          })
          .catch(error => {
            console.log(error)
          })

        axios.get('commoncode/accts_type/202')
          .then(response => {
            this.accts2 = year > 2023 ? response.data.filter(x=> x.code != 202034 && x.code != 202043) : response.data;
          })
          .catch(error => {
            console.log(error)
          })
        axios.get('commoncode/accts_org_id_year/' + user_org.id + '/' + year)
          .then(response => {
            this.acctsGridRows = response.data;
          })
          .catch(error => {
            console.log(error)
          });
      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      showActive() {
        //this.isActive = 
        // alert(this.isActive);
        // let dd = this.gridRows.filter(d => {
				// 	return (d.bfr_debit_amnt != null || d.bfr_credit_amnt != null || d.cur_debit_amnt != null || d.cur_credit_amnt != null || d.lst_debit_amnt != null || d.lst_credit_amnt != null);
				// });
        // this.filteredGridRows();
        // alert(JSON.stringify(dd));
      },

      formatPrice(value) {
        // return value == null || value == "0" ? "-" : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      totalBalance(item, type) {
        return type == 1 ? (Number(item.bfr_debit_amnt)+Number(item.cur_debit_amnt) > Number(item.bfr_credit_amnt)+Number(item.cur_credit_amnt) ? (Number(item.bfr_debit_amnt)+Number(item.cur_debit_amnt))-(Number(item.bfr_credit_amnt)+Number(item.cur_credit_amnt)) : 0) : (Number(item.bfr_credit_amnt)+Number(item.cur_credit_amnt) > Number(item.bfr_debit_amnt)+Number(item.cur_debit_amnt) ? (Number(item.bfr_credit_amnt)+Number(item.cur_credit_amnt))-(Number(item.bfr_debit_amnt)+Number(item.cur_debit_amnt)) : 0)
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      acctsEditItem (item) {
        this.acctsEditedIndex = this.acctsGridRows.indexOf(item)
        this.acctsEditedItem = Object.assign({}, item)
        let ac1 = this.accts1.find( i => i.code == this.acctsEditedItem.acct_type1 );
        this.acctsEditedItem.acct_type1 = { code: ac1.code, comb_name: ac1.comb_name }
        let ac2 = this.accts2.find( i => i.code == this.acctsEditedItem.acct_type2 );
        this.acctsEditedItem.acct_type2 = { code: ac2.code, comb_name: ac2.comb_name }
        // this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      acctsDeleteItem (item) {
        this.acctsEditedIndex = this.acctsGridRows.indexOf(item)
        this.acctsEditedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // alert(JSON.stringify(this.acctsEditedItem));
        axios.delete('commoncode/' + this.acctsEditedItem.id)
          .then(response => {
            alert('Successfully deleted!')
            this.acctsGridRows.splice(this.acctsEditedIndex, 1);
            this.closeDelete();
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            console.log(error)
          });
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      saveAccts () {
        // this.$v.$touch();
        if (!this.$refs.form.validate()) {
          return;
        }
        if (this.acctsEditedIndex < 0 && this.acctsGridRows.filter(x=> x.notes == this.acctsEditedItem.notes).length > 0) {
          alert('Бүртгэлтэй дансны дугаар байна!');
          return;
        }
        // if (this.$v.$invalid) {
        //   alert('Error!');
        // } else {
        //   alert('Success!');
        // }
        // return;
        // console.log(JSON.stringify(this.acctsEditedItem.acct_type1));
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let year = user_dt[0].substr(0, 4)
        this.acctsEditedItem.year = year
        this.acctsEditedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.acctsEditedItem.parent_code = "101";
        // this.acctsEditedItem.code = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.acctsEditedItem.acct_type1_nm = this.acctsEditedItem.acct_type1.comb_name;
        this.acctsEditedItem.acct_type2_nm = this.acctsEditedItem.acct_type2.comb_name;
        this.acctsEditedItem.acct_type1 = this.acctsEditedItem.acct_type1.code;
        this.acctsEditedItem.acct_type2 = this.acctsEditedItem.acct_type2.code;
        if (this.acctsEditedIndex > -1) {
          axios.put('commoncode/' + this.acctsGridRows[this.acctsEditedIndex].id, this.acctsEditedItem)
            .then(response => {
              // this.$router.go()
              // alert(JSON.stringify(this.acctsGridRows[this.acctsEditedIndex]));
              // this.acctsGridRows[this.acctsEditedIndex] = response.data;
              // this.acctsGridRows.replace(this.acctsEditedIndex, response.data);
              this.acctsGridRows.push(response.data);
              // alert(JSON.stringify(this.acctsGridRows[this.acctsEditedIndex]));
              this.resetAcctsForm();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          this.acctsEditedItem.code = JSON.parse(sessionStorage.getItem('user_org')).id;
          axios.post('commoncode/store', this.acctsEditedItem)
            .then(response => {
              // this.$router.go()
              this.acctsGridRows.push(response.data);
              this.resetAcctsForm();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
      },
      
      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => Number(a) + (Number(b[key]) || 0), 0)
      },

      reloadMain() {
        this.close();
        this.initialize();
      },

      resetAcctsForm() {
        this.acctsEditedIndex = -1;
        this.$refs.form.reset()
        // this.acctsEditedItem = Object.assign({}, this.acctsDefaultItem);
      },
      
      
      wholePrint() {
        if (this.filteredGridRows.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        var org = this.orgs.filter(x=> x.id==org_id)[0]

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 0.5, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 8.0, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Аж ахуйн нэгжийн нэр)', 0.5, 0.6);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Тайлант үе)', 8.0, 0.6);
        // create a line under heading 
        doc.setLineWidth(0.001).line(0.5, 0.7, 11.0, 0.7);
        // set selected account number and customer name
        doc.setFont("Arial-Mon", "bold").setFontSize(13).text('ГҮЙЛГЭЭ БАЛАНС', 0.5, 1.0);
        
        // set headers and data for autoTable
        const headers2 = [
          ['Дансны нэр', 'Дансны код', 'Дебит эхний үлдэгдэл', 'Кредит эхний үлдэгдэл', 'Дебит гүйлгээ', 'Кредит гүйлгээ', 'Дебит эцсийн үлдэгдэл', 'Кредит эцсийн үлдэгдэл']
        ];
        let data2 = [];
        this.filteredGridRows.forEach(function (e) {
          var ids = [];
          ids.push(e.name);
          ids.push(e.acct);
          ids.push(common.moneyFormat(e.bfr_debit_amnt));
          ids.push(common.moneyFormat(e.bfr_credit_amnt));
          ids.push(common.moneyFormat(e.cur_debit_amnt));
          ids.push(common.moneyFormat(e.cur_credit_amnt));
          ids.push(common.moneyFormat(e.lst_debit_amnt));
          ids.push(common.moneyFormat(e.lst_credit_amnt));
          data2.push(ids);
        });
        // Footer line for summary amount (added on 5/2/2022)
        var ids2 = [];
        ids2.push("НИЙТ ДҮН");
        ids2.push(null);
        ids2.push(this.formatPrice(this.sumField('bfr_debit_amnt')));
        ids2.push(this.formatPrice(this.sumField('bfr_credit_amnt')));
        ids2.push(this.formatPrice(this.sumField('cur_debit_amnt')));
        ids2.push(this.formatPrice(this.sumField('cur_credit_amnt')));
        ids2.push(this.formatPrice(this.sumField('lst_debit_amnt')));
        ids2.push(this.formatPrice(this.sumField('lst_credit_amnt')));
        data2.push(ids2);
        
        if (data2.length == 0) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, body: data2, margin: { left: 0.5, top: 1.1 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 9} })
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 4.5, finalY+0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 4.5, finalY+0.8);
        
        // saving file
        doc.save(`balance.pdf`);
      },
      
      closeUpdateAmnt () {
        this.dialogUpdateAmnt = false
      },

      updateAmnt () {
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        this.amntYear1 = Number(date_range[0].substr(0, 4))
        this.amntYear2 = this.amntYear1 + 1
        this.dialogUpdateAmnt = true
      },

      updateAmntConfirm () {
        let dts = [];
        var year = this.amntYear2;
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        // 2024.04.10 (5,6,7,8,9 ehelsen dans huulagdahgui bn)
        // this.filteredGridRows.filter(x=> x.acct < 610101).forEach(function (e) {
        this.filteredGridRows.filter(x=> x.acct < 500000).forEach(function (e) {
            dts.push({
              year: year,
              org_id: org_id,
              acct_id: e.acct,
              acct_code: e.acct,
              debit_amnt: e.lst_debit_amnt,
              credit_amnt: e.lst_credit_amnt
            });
        });

        axios.post('reptperiod/store', dts)
          .then(response => {
            alert('Мэдээллийг амжилттай шинэчлэлээ.');
            this.closeUpdateAmnt()
          })
          .catch(error => {
            alert('Мэдээллийг шинэчлэхэд алдаа гарлаа.');
            console.log(error)
          })
      },

    },
  }
</script>