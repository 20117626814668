<template>
  <div class="tabs pa-5">
    <v-tabs
      v-model="activeTab" 
      hide-slider
      height="0"
    >
      <v-tab
        v-for="tab of tabs"
        :key="tab.id"
        :to="tab.route"
        disabled
        exact
      >
        <!-- <v-icon>{{ tab.icon }}</v-icon>
        {{ tab.name }} -->
      </v-tab>
      <v-tab-item
        v-for="tab of tabs"
        :key="tab.id"
        :value="tab.route"
      >
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
    <v-btn
      v-for="btn of tabs"
      :key="btn.id"
      :to="btn.route"
    >
      <v-icon small class="mr-1">{{ btn.icon }}</v-icon>
      {{ btn.name }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        activeTab: `/settings`,
        tabs: [
          { id: '1', name: 'Аж ахуйн нэгж', route: `/settings/orgs`, icon: 'mdi-desktop-mac-dashboard' },
          // { id: '2', name: 'Данс', route: `/settings/accounts`, icon: 'mdi-account-tie' },
          { id: '3', name: 'Бусад код', route: `/settings/misc`, icon: 'mdi-creative-commons' }
        ],
      };
    }
  };
</script>
