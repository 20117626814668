<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    class="elevation-1"
    dense
    item-key="id"
    show-select
    calculate-widths
    fixed-header
    height="60vh"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Аж ахуйн нэгжийн жагсаалт</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-if="!readonly_check"
              v-bind="attrs"
              v-on="on"
            >
              Нэмэх
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.reg_id"
                      label="Регистрийн дугаар *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Нэр *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      label="Утас *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.ceo_firstname"
                      label="Захирал *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.acct_firstname"
                      label="Нягтлан бодогч *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.cshr_firstname"
                      label="Кассын нярав"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.corp_inco_tax_rate"
                      label="ААНОАТ-н хувь *"
                      :rules="[required]"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.intr_inco_tax_rate"
                      label="Хүүгийн орлогын албан татварын хувь *"
                      :rules="[required]"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              slot="activator"
              color="red lighten-2"
              dark
              class="mb-2 ml-2"
              v-if="!readonly_check"
              @click="deleteItem"
            >
              Устгах
              <v-icon small class="ml-1">mdi-delete</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон аж ахуйн нэгжийн мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <th style="border-right: 1px solid #c8c8c8">
          <v-icon small>mdi-filter-variant</v-icon>
				</th>
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <!-- <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div> -->
        </th>
      </tr>
    </template>
 
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="!readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:item.no = "{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  
  let bgColorCode = "orange lighten-5";

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      loadTable: true,
      headers: [
        {
          text: '№',
          align: 'center',
          sortable: false,
          value: 'no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Регистрийн дугаар', value: 'reg_id', divider: true, class: bgColorCode, width: '1%' },
        // { text: 'Улсын бүртгэлийн дугаар', value: 'reg_num', divider: true, class: bgColorCode, width: '180px' },
        { text: 'Нэр', value: 'name', divider: true, class: bgColorCode },
        { text: 'Утас', value: 'phone', divider: true, class: bgColorCode, width: '1%' },
        // { text: 'Пэйж хуудас', value: 'homepage', divider: true, class: bgColorCode },
        { text: 'Захирал', value: 'ceo_firstname', divider: true, class: bgColorCode },
        { text: 'Нягтлан бодогч', value: 'acct_firstname', divider: true, class: bgColorCode },
        { text: 'Кассын нярав', value: 'cshr_firstname', divider: true, class: bgColorCode },
        { text: 'ААНОАТ-н хувь', value: 'corp_inco_tax_rate', divider: true, class: bgColorCode },
        { text: 'Хүүгийн орлогын албан татварын хувь', value: 'intr_inco_tax_rate', divider: true, class: bgColorCode },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, align: 'center', width: '1%' },
      ],
      filters: {
        reg_id: [],
				name: [],
				phone: [],
				ceo_firstname: [],
        acct_firstname: [],
        cshr_firstname: [],
        corp_inco_tax_rate: [],
        intr_inco_tax_rate: [],
			},
      gridRows: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        reg_id: '',
        name: '',
        phone: '',
        ceo_firstname: '',
        acct_firstname: '',
        cshr_firstname: '',
        corp_inco_tax_rate: '',
        intr_inco_tax_rate: '',
      },
      defaultItem: {
        id: '',
        reg_id: '',
        name: '',
        phone: '',
        ceo_firstname: '',
        acct_firstname: '',
        cshr_firstname: '',
        corp_inco_tax_rate: '',
        intr_inco_tax_rate: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val] ? d[val] : '-');
			},

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        // console.log(JSON.stringify(this.selected))
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        this.selected.forEach(function (e) {
            ids.push(e.id);
        });

        axios.delete('org/' + ids)
          .then(response => {
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.reloadMain()
            this.closeDelete()
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })

        // // this.gridRows.splice(this.editedIndex, 1)
        // let sCount = 0
        // this.selected.forEach(element => {
        //   axios.delete('org/' + element.id)
        //     .then(response => {
        //       sCount ++;
        //       console.log(JSON.stringify(response.data));
        //     })
        //     .catch(error => {
        //       console.log(error)
        //     })
        // });
        // if (this.selected.length == sCount) {
        //   alert('Successfully deleted!')
        // }
        // this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        
        this.editedItem.user_id = JSON.parse(sessionStorage.getItem('user')).id
        if (this.editedIndex > -1) {
          axios.put('org/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              // this.$router.go()
              alert('Аж ахуйн нэгжийн мэдээлэл амжилттай засварлагдлаа.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          // Object.assign(this.gridRows[this.editedIndex], this.editedItem)
        } else {
          if (this.gridRows.length >= sessionStorage.getItem('org_limit')) {
            alert('Бүртгэх боломжтой аж ахуйн нэгжийн тоо хэтэрсэн тул бүртгэх боломжгүй.');
            return;
          }

          axios.post('org/store', this.editedItem)
            .then(response => {
              // this.$router.go()
              alert('Аж ахуйн нэгжийн мэдээлэл амжилттай бүртгэгдлээ.');
              this.reloadMain();
              // console.log(JSON.stringify(response.data));
            })
            .catch(error => {
              console.log(error)
            })
          //this.gridRows.push(this.editedItem)
        }
        this.close()
      },
      
      reloadMain() {
        // this.initialize();
        this.$router.go()
      },

    },
  }
</script>