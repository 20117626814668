<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>ӨМЧИЙН ӨӨРЧЛӨЛТИЙН ТАЙЛАН</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-col>
          <tr>
            <td>{{org_nm}}</td>
          </tr>
          <tr>
            <td><small>(Аж ахуйн нэгжийн нэр)</small></td>
          </tr>
        </v-col>
        <v-col cols="12" sm="3">
          <tr>
            <td><small>Тайлант үе : {{dateRangeText}}</small></td>
          </tr>
          <tr>
            <td align="right"><small>/төгрөгөөр/</small></td>
          </tr>
        </v-col>
        <!-- Print (start) -->
        <v-btn
          color="blue lighten-3"
          dark
          class="mb-2 mr-2"
          @click="wholePrint()"
        >
          PDF <i class="mdi mdi-printer" aria-hidden="true"></i>
        </v-btn>
        <!-- Print (end) -->
        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows2"
          :fields="export_fields"
          :labels="export_labels"
          name="report_assets.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-2">
            Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->

        <v-dialog
          v-model="dialog"
          max-width="1200px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              v-if="!readonly_check"
              @click="checkData"
            >
              Засах
              <v-icon small class="ml-1">mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              Өмчийн өөрчлөлтийн тайлангийн өгөгдөл засварлах хэсэг
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <table class="rprtasset">
                      <thead>
                        <tr class="fs12">
                          <th class="text-left" width="250">
                            Үзүүлэлт
                          </th>
                          <th class="text-left" width="120">
                            Өмч
                          </th>
                          <th class="text-left" width="120">
                            Халаасны хувьцаа
                          </th>
                          <th class="text-left" width="120">
                            Нэмж төлөгдсан капитал
                          </th>
                          <th class="text-left" width="120">
                            Хөрөнгийн дахин үнэлгээний нэмэгдэл
                          </th>
                          <th class="text-left" width="120">
                            Гадаад валютын хөрвүүлэлтийн нөөц
                          </th>
                          <th class="text-left" width="120">
                            Эздийн өмчийн бусад хэсэг
                          </th>
                          <th class="text-left" width="120">
                            Хуримтлагдсан ашиг
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="fs12">Нягтлан бодох бүртгэлийн бодлогын өөрчлөлтийн нөлөө, алдааны залруулга</td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c1"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c2"
                              class="fs13"
                              outlined
                              dense
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c3"
                              class="fs13"
                              outlined
                              dense
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c4"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c5"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r1c6"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td class="fs12">Тайлант үеийн цэвэр ашиг (алдагдал)</td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c1"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c2"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c3"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c4"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c5"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r2c6"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td class="fs12">Бусад дэлгэрэнгүй орлого</td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c1"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c2"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c3"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c4"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c5"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c6"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r3c7"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="fs12">Зарласан ногдол ашиг</td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c1"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c2"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c3"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c4"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c5"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c6"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r4c7"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="fs12">Дахин үнэлгээний нэмэгдлийн хэрэгжсэн дүн</td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c1"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c2"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c3"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c4"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c5"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c6"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="editedItem.r5c7"
                              class="fs13"
                              outlined
                              dense
                              full-width
                              type="number"
                              background-color="blue lighten-5"
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-toolbar>
    </template>

    <template v-slot:item.f1="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f1, 'f1'))}}</span>
    </template>
    <template v-slot:item.f2="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f2, 'f2'))}}</span>
    </template>
    <template v-slot:item.f3="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f3, 'f3'))}}</span>
    </template>
    <template v-slot:item.f4="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f4, 'f4'))}}</span>
    </template>
    <template v-slot:item.f5="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f5, 'f5'))}}</span>
    </template>
    <template v-slot:item.f6="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f6, 'f6'))}}</span>
    </template>
    <template v-slot:item.f7="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f7, 'f7'))}}</span>
    </template>
    <template v-slot:item.f8="{ item }">
      <span>{{formatPrice(checkAmnt(item, item.f8, 'f8'))}}</span>
    </template>

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>
<style>
  .fs12 {
    font-size: 12px;
    line-height: 1.4;
  }
  .fs13 {
    font-size: 13px;
  }
  .rprtasset td {
    padding: 4px 2px;
    /* font-size: 12px; */
  }
</style>
<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'

  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      footerProps: {'items-per-page-options': [10, 20, 50, 100, -1]},
      search: '',
      selected: [],
      dialog: false,
      orgs: [],
      loadTable: true,
      org_nm: '',
      date_range: ['', ''],
      headers: [
        { text: '№', value: 'notes', divider: true, sortable: false, class: bgColorCode, width: '20px' },
        { text: 'Үзүүлэлт', value: 'name', divider: true, sortable: false, class: bgColorCode },
        { text: 'Өмч', value: 'f1', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Халаасны хувьцаа', value: 'f2', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Нэмж төлөгдсан капитал', value: 'f3', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Хөрөнгийн дахин үнэлгээний нэмэгдэл', value: 'f4', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Гадаад валютын хөрвүүлэлтийн нөөц', value: 'f5', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Эздийн өмчийн бусад хэсэг', value: 'f6', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Хуримтлагдсан ашиг', value: 'f7', divider: true, sortable: false, class: bgColorCode, align: 'right' },
        { text: 'Нийт дүн', value: 'f8', divider: true, sortable: false, class: bgColorCode, align: 'right' },
      ],
      export_fields: [
        'notes', 'name', 'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8'
      ],
      export_labels: {
        notes: '№',
        name: 'Үзүүлэлт',
        f1: 'Өмч',
        f2: 'Халаасны хувьцаа',
        f3: 'Нэмж төлөгдсан капитал',
        f4: 'Хөрөнгийн дахин үнэлгээний нэмэгдэл',
        f5: 'Гадаад валютын хөрвүүлэлтийн нөөц',
        f6: 'Эздийн өмчийн бусад хэсэг',
        f7: 'Хуримтлагдсан ашиг',
        f8: 'Нийт дүн'
      },
      filters: {
        notes: [],
				name: [],
			},
      gridRows: [],
      rads: [],
      editedIndex: -1,
      editedItem: {
        r1c1: '',
        r1c2: '',
        r1c3: '',
        r1c4: '',
        r1c5: '',
        r1c6: '',
        r1c7: '',
        r2c1: '',
        r2c2: '',
        r2c3: '',
        r2c4: '',
        r2c5: '',
        r2c6: '',
        r2c7: '',
        r3c1: '',
        r3c2: '',
        r3c3: '',
        r3c4: '',
        r3c5: '',
        r3c6: '',
        r3c7: '',
        r4c1: '',
        r4c2: '',
        r4c3: '',
        r4c4: '',
        r4c5: '',
        r4c6: '',
        r4c7: '',
        r5c1: '',
        r5c2: '',
        r5c3: '',
        r5c4: '',
        r5c5: '',
        r5c6: '',
        r5c7: '',
        org_id: '',
        s_dt: '',
        f_dt: '',
      },
    }),

    computed: {
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
      filteredGridRows2() {
        return this.filteredGridRows.map(obj => {
              let rObj = {"notes":obj.notes,"name":obj.name,"f1":this.checkAmnt(obj, obj.f1, 'f1'),"f2":this.checkAmnt(obj, obj.f2, 'f2'),
                          "f3":this.checkAmnt(obj, obj.f3, 'f3'),"f4":this.checkAmnt(obj, obj.f4, 'f4'),"f5":this.checkAmnt(obj, obj.f5, 'f5'),
                          "f6":this.checkAmnt(obj, obj.f6, 'f6'),"f7":this.checkAmnt(obj, obj.f7, 'f7'),"f8":this.checkAmnt(obj, obj.f8, 'f8')}
              return rObj
        });
			},
      dateRangeText () {
        return this.date_range.join(' ~ ')
      },
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
      this.getOrgInfo()
    },

    methods: {
      initialize () {
        this.org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        this.date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let parent_code = '203'
        let rprt_code = '301003'
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1],
          p_cd: parent_code,
          r_cd: rprt_code
        };
        axios.get('commoncode/accts_rprt_code', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.acctsGridRows));
          })
          .catch(error => {
            console.log(error)
          });
        axios.get('rprtassetdtls', { params })
          .then(response => {
            this.rads = response.data;
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            console.log(error)
          });
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      formatPrice(value) {
        return value == null || value == "0" || value == "0.00" ? "-" : Number(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      totalBalance(item, type) {
        return type == 1 ? (Number(item.debit_amnt)+Number(item.credit_amnt)) : 0
      },

      checkAmnt(item, val, type) {
        if (type == 'f8') {
          if (item.code == '203009') {
            return this.filteredGridRows.filter(f => Number(f.code) == 203002).reduce((a, b) => Number(a) + (Number(b['f1'])+Number(b['f2'])+Number(b['f3'])+Number(b['f4'])+Number(b['f5'])+Number(b['f6'])+Number(b['f7'])*-1 || 0), 0)
             + this.filteredGridRows.filter(f => Number(f.code) == 203001 || Number(f.code) > 203003).reduce((a, b) => Number(a) + (Number(b['f1'])+Number(b['f2'])+Number(b['f3'])+Number(b['f4'])+Number(b['f5'])+Number(b['f6'])+Number(b['f7']) || 0), 0);
          } else if (item.code == '203002') {
            return Number(item.f1)+Number(item.f2)+Number(item.f3)+Number(item.f4)+Number(item.f5)+Number(item.f6)+(Number(item.f7)*-1)
          } else if (item.code == '203003') {
            return this.filteredGridRows.filter(f => Number(f.code) == 203001).reduce((a, b) => Number(a) + (Number(b['f1'])+Number(b['f2'])+Number(b['f3'])+Number(b['f4'])+Number(b['f5'])+Number(b['f6'])+Number(b['f7']) || 0), 0)
             + this.filteredGridRows.filter(f => Number(f.code) == 203002).reduce((a, b) => (Number(b['f1'])+Number(b['f2'])+Number(b['f3'])+Number(b['f4'])+Number(b['f5'])+Number(b['f6'])+Number(b['f7'])*-1 || 0), 0);
          }
          return Number(item.f1)+Number(item.f2)+Number(item.f3)+Number(item.f4)+Number(item.f5)+Number(item.f6)+Number(item.f7)
        } else if (item.code == '203009') {
          if (type == 'f7') {
            return this.filteredGridRows.filter(f => Number(f.code) == 203002).reduce((a, b) => Number(a) + (Number(b['f7'])*-1 || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) == 203001 || Number(f.code) > 203003).reduce((a, b) => Number(a) + (Number(b[type]) || 0), 0);
          }
          return this.filteredGridRows.filter(f => Number(f.code) > 203002).reduce((a, b) => Number(a) + (Number(b[type]) || 0), 0);
        } else if (item.code == '203002' && type == 'f7') {
          return val * -1;
        } else if (item.code == '203003' && type == 'f7') {
          return this.filteredGridRows.filter(f => Number(f.code) == 203001).reduce((a, b) => Number(a) + (Number(b['f7']) || 0), 0) + this.filteredGridRows.filter(f => Number(f.code) == 203002).reduce((a, b) => Number(a) + (Number(b['f7'])*-1 || 0), 0);
        } else {
          return val
        }
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      checkData () {
        if (typeof this.rads !== 'undefined' && this.rads && this.rads.length > 0) {
          this.editedIndex = 0;
          this.editedItem = Object.assign({}, this.rads[0])
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          
        })
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.editedItem.s_dt = user_dt[0];
        this.editedItem.f_dt = user_dt[1];
        
        if (this.editedIndex > -1) {
          axios.put('rprtassetdtls/' + this.rads[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай засагдлаа.');
              this.reloadMain();
            })
            .catch(error => {
              alert('Мэдээлэл засварлахад алдаа гарлаа.');
              console.log(error)
            })
        } else {
          axios.post('rprtassetdtls/store', this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай орууллаа.');
              this.reloadMain();
            })
            .catch(error => {
              alert('Мэдээлэл оруулахад алдаа гарлаа.');
              console.log(error)
            })
        }
        this.close()
      },
      
      reloadMain() {
        this.initialize();
      },

      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => Number(a) + (Number(b[key]) || 0), 0)
      },

      wholePrint() {
        if (this.filteredGridRows2.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var org = this.orgs.filter(x=> x.id==org_id)[0]
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // set report title on top
        doc.setFont("Arial-Mon", "bold").setFontSize(12).text('ӨМЧИЙН ӨӨРЧЛӨЛТИЙН ТАЙЛАН', 240, 30);
        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 20, 60);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 520, 60);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('( Аж ахуйн нэгжийн нэр )', 20, 67);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('/ төгрөгөөр /', 520, 67);
        
        // set headers and data for autoTable
        const headers2 = [
          ['№', 'Үзүүлэлт', 'Өмч', 'Халаасны хувьцаа', 'Нэмж төлөгдсөн капитал', 'Хөрөнгийн дахин үнэлгээний нэмэгдэл',
           'Гадаад валютын хөрвүүлэлтийн нөөц', 'Эздын өмчийн бусад хэсэг', 'Хуримтлагдсан ашиг', 'Нийт дүн']
        ];
        let data2 = [];
        this.filteredGridRows2.forEach(function (e) {
          var ids = [];
          ids.push(e.notes);
          ids.push(e.name);
          ids.push(common.moneyFormat(e.f1));
          ids.push(common.moneyFormat(e.f2));
          ids.push(common.moneyFormat(e.f3));
          ids.push(common.moneyFormat(e.f4));
          ids.push(common.moneyFormat(e.f5));
          ids.push(common.moneyFormat(e.f6));
          ids.push(common.moneyFormat(e.f7));
          ids.push(common.moneyFormat(e.f8));
          data2.push(ids);
        });

        if (data2.length == 0) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, headStyles: { fillColor: 'white', lineWidth: 0.1, lineColor: 'gray', halign: 'center', textColor: 'black'},
                        body: data2, tableLineWidth: 0.75, theme: "grid",
                        columnStyles: { 2: { halign: 'right' }, 3: { halign: 'right' }, 4: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' }, 8: { halign: 'right' }, 9: { halign: 'right' } },
                        margin: { left: 20, top: 70, right: 20 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 9} })
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 250, finalY+30);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 250, finalY+45);
        
        // saving file
        doc.save(`report_assets.pdf`);
      },

    },
  }
</script>
