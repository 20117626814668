<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    ref="table1"
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    sort-by="invoice_dt"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-row align="center">
          <v-col cols="2">
            <v-subheader>
              Данс:
            </v-subheader>
          </v-col>
          <v-col cols="10">
            <v-autocomplete
              v-model="select"
              :hint="`${selectNo}`"
              :items="accts"
              item-text="comb_name"
              item-value="notes"
              label="Дансаа сонгоно уу?"
              persistent-hint
              return-object
              no-data-text="Тохирох утга олдсонгүй!"
              append-icon="mdi-select-marker"
              single-line
              v-on:input="changeAcct"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="2">
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              v-model="selectCust"
              label="Харилцагч"
              :items="custs"
              item-text="name"
              item-value="id"
              outlined
              dense
              clearable
              no-data-text="Тохирох утга олдсонгүй!"
              v-on:input="changeAcct"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        
        <!-- Print (start) -->
        <v-btn
          color="warning lighten-2"
          dark
          class="mb-2 mr-2"
          @click="wholePrint()"
        >
          PDF <i class="mdi mdi-printer" aria-hidden="true"></i>
        </v-btn>
        <!-- Print (end) -->

        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows"
          :fields="export_fields"
          :labels="export_labels"
          name="statement.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-2">
            Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->

        <!-- Edit (start) -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="headline green lighten-2" primary-title>
              {{ formTitle }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-combobox
                      v-model="editedItem.txn_type"
                      label="Гүйлгээний төрөл *"
                      :items="txntys"
                      item-text="name"
                      item-value="code"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                      :readonly="editedIndex === -1 ? false : true"
                    >
                    </v-combobox>
                  </v-col>                  
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.curr_rate"
                      label="Ханш"
                      outlined
                      readonly
                      dense
                      hide-details
                      v-if="editedItem.txn_type.code == '102003'"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.invoice_dt"
                          label="Огноо *"
                          append-icon="mdi-calendar"
                          :rules="[required, datecheck]"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          type="date-local"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.invoice_dt"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.cust_id"
                      label="Харилцагч"
                      :items="custs"
                      item-text="name"
                      outlined
                      dense
                      hide-details
                      return-object
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.txn_value"
                      label="Гүйлгээний утга *"
                      :rules="[required]"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.debit_acct_no"
                      label="Дебит данс *"
                      :items="accts"
                      item-text="comb_name"
                      item-value="notes"
                      :rules="[required]"
                      validate-on-blur
                      outlined
                      dense
                      return-object
                      hide-details
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="editedItem.credit_acct_no"
                      label="Кредит данс *"
                      :items="accts"
                      item-text="comb_name"
                      item-value="notes"
                      :rules="[required]"
                      outlined
                      dense
                      cache-items
                      return-object
                      hide-details
                      no-data-text="Тохирох утга олдсонгүй!"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.debit_amnt"
                      label="Дебит дүн"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.credit_amnt"
                      label="Кредит дүн"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <h4 style="color:red">* Заавал бөглөх талбар</h4>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Цуцлах
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Хадгалах
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Edit (end) -->

        <!-- Delete (start) -->
        <!-- <v-dialog
          persistent
          no-click-animation
          v-model="dialogDelete"
          width="500"
        >
          <template v-slot:activator="{ click }">
          <v-btn
            slot="activator"
            color="red lighten-2"
            dark
            class="mb-2"
            @click="deleteItem"
          >
            Устгах
            <v-icon small class="ml-1">mdi-delete</v-icon>
          </v-btn>
          </template>
        
          <v-card>
            <v-card-title
              class="headline red lighten-2"
              primary-title
            >
              Мэдээллийг устгах уу?
            </v-card-title>
    
            <v-card-text>
              Сонгогдсон гүйлгээний мэдээллийг устгахад итгэлтэй байна уу?
              <br>Дахин сэргээх боломжгүйг анхаарна уу.
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="closeDelete"
              >
                Үгүй
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                @click="deleteItemConfirm"
              >
                Тийм
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <!-- Delete (end) -->


      </v-toolbar>
    </template>
    <template v-slot:header="{ header }">
      <tr class="grey lighten-3">
        <!-- <th style="border-right: 1px solid #c8c8c8">
          <v-icon>mdi-filter-variant</v-icon>
				</th> -->
        <th style="border-right: 1px solid #c8c8c8" v-for="header in headers" :key="header.text">
          <div v-if="filters.hasOwnProperty(header.value)">
            <v-autocomplete
              flat
              hide-details
              multiple
              attach
              small-chips
              dense
              clearable
              :items="columnValueList(header.value)"
              v-model="filters[header.value]"
              no-data-text="дата байхгүй байна"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index < 5">
                  <span class="small--text"> 
                    {{ item }} 
                  </span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption" > 
                  (+{{ filters[header.value].length - 5 }} others) 
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="!filters.hasOwnProperty(header.value)">
            <v-icon small>mdi-filter-variant</v-icon>
          </div>
        </th>
      </tr>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        v-if="checkTxnType(item)"
        :disabled="readonly_check"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        v-if="checkTxnType(item)"
        @click="printItem(item)"
      >
        mdi-printer
      </v-icon>
    </template>

    <template v-slot:item.debit_amnt="{ item }">
      <span>{{formatPrice(item.debit_amnt)}}</span>
    </template>
    <template v-slot:item.credit_amnt="{ item }">
      <span>{{formatPrice(item.credit_amnt)}}</span>
    </template>
    <template v-slot:item.last_amnt="{ item }">
      <span>{{formatPrice(item.last_amnt)}}</span>
      <!-- <span>{{formatPrice(totalBalance(item))}}</span> -->
    </template>
    <!-- <template v-slot:item.cust_id="{ item }">
      <span>{{custName(item.cust_id)}}</span>
    </template> -->

    <template v-slot:body.prepend>
      <tr class="bold--text">
        <th />
        <th />
        <th class="text-left">эхний үлдэгдэл</th>
        <th />
        <th />
        <th />
        <th />
        <th class="text-right">{{ formatPrice(first_amnt) }}</th>
        <th />
      </tr>
    </template>

    <template v-slot:body.append>
      <tr class="bold--text">
        <th />
        <th />
        <th class="text-left">нийт дүн</th>
        <th />
        <th />
        <th class="text-right">{{ formatPrice(sumField('debit_amnt')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('credit_amnt')) }}</th>
        <th />
      </tr>
    </template>

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'

  let bgColorCode = "orange lighten-5";

  export default {
    data: () => ({
      footerProps: {'items-per-page-options': [10, 20, 50, 100, 500, -1]},
      pagination: {
        sortBy: 'name'
      },
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      accts: [],
      txntys: [],
      custs: [],
      orgs: [],
      select: '',
      selectNo: '',
      selectCust: '',
      first_amnt: '',
      // select: { name: 'Кассанд байгаа бэлэн мөнгө (төгрөг)', notes: '100101' },
      loadTable: false,
      headers: [
        {
          text: 'Баримтын дугаар',
          align: 'start',
          sortable: false,
          value: 'invoice_no',
          divider: true, 
          class: bgColorCode,
          width: '1%'
        },
        { text: 'Огноо', value: 'invoice_dt', divider: true, class: bgColorCode, width: '110px' },
        { text: 'Гүйлгээний утга', value: 'txn_value', divider: true, class: bgColorCode },
        { text: 'Харилцагч', value: 'cust_name', divider: true, class: bgColorCode },
        { text: 'Харьцсан данс', value: 'rel_acct_no', divider: true, class: bgColorCode, width: '1%' },
        { text: 'Дебит мөнгөн дүн', value: 'debit_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Кредит мөнгөн дүн', value: 'credit_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Эцсийн дүн', value: 'last_amnt', divider: true, class: bgColorCode, align: 'right', width: '120px' },
        { text: 'Үйлдэл', value: 'actions', sortable: false, divider: false, class: bgColorCode, width: '1%', align: 'center' },
      ],
      export_fields: [
        'invoice_no', 'invoice_dt', 'txn_value', 'cust_name',
        'rel_acct_no', 'debit_amnt', 'credit_amnt', 'last_amnt'
      ],
      export_labels: {
        invoice_no: 'Баримтын дугаар',
        invoice_dt: 'Огноо',
        txn_value: 'Гүйлгээний утга',
        cust_name: 'Харилцагч',
        rel_acct_no: 'Харьцсан данс',
        debit_amnt: 'Дебит мөнгөн дүн',
        credit_amnt: 'Кредит мөнгөн дүн',
        last_amnt: 'Эцсийн дүн'
      },
      gridRows: [],
      filters: {
        invoice_no: [],
        invoice_dt: [],
				txn_value: [],
				cust_name: [],
				rel_acct_no: [],
        debit_amnt: [],
				credit_amnt: [],
				// last_amnt: [],
			},
      editedIndex: -1,
      editedItem: {
        txn_type: {code: '102001', name: 'Энгийн гүйлгээ'},
        invoice_dt: new Date().toISOString().substr(0, 10),
        txn_value: '',
        debit_acct_no: '',
        credit_acct_no: '',
        invoice_no: '1001',
        debit_amnt: '',
        credit_amnt: '',
        curr_rate: '2850',
        org_id: '',
      },
      defaultItem: {
        txn_type: {code: '102001', name: 'Энгийн гүйлгээ'},
        invoice_dt: new Date().toISOString().substr(0, 10),
        txn_value: '',
        debit_acct_no: '',
        credit_acct_no: '',
        invoice_no: '1001',
        debit_amnt: '',
        credit_amnt: '',
        curr_rate: '2850',
        org_id: '',
      },




      // heading: "Sample PDF Generator",
      // moreText: [
      //   "This is another few sentences of text to look at it.",
      //   "Just testing the paragraphs to see how they format.",
      //   "jsPDF likes arrays for sentences.",
      //   "Do paragraphs wrap properly?",
      //   "Yes, they do!",
      //   "What does it look like?",
      //   "Not bad at all."
      // ],
      // items_pdf: [
      //   { title: "Item 1", body: "I am item 1 body text" },
      //   { title: "Item 2", body: "I am item 2 body text" },
      //   { title: "Item 3", body: "I am item 3 body text" },
      //   { title: "Item 4", body: "I am item 4 body text" }
      // ],
      headers_pdf: [
        { title: 'Баримтын дугаар', dataKey: 'invoice_no' },
        { title: 'Огноо', dataKey: 'invoice_dt' },
        { title: 'Гүйлгээний утга', dataKey: 'txn_value' },
        { title: 'Харилцагч', dataKey: 'cust_name' },
        { title: 'Харьцсан данс', dataKey: 'rel_acct_no' },
        { title: 'Дебит мөнгөн дүн', dataKey: 'debit_amnt' },
        { title: 'Кредит мөнгөн дүн', dataKey: 'credit_amnt' },
        { title: 'Эцсийн дүн', dataKey: 'last_amnt' },
      ],

    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Шинээр бүртгэх' : 'Засварлах'
      },
      filteredGridRows() {
        // return this.gridRows.filter((i) => {
        //   return !this.selectCust || (i.cust_id === this.selectCust);
        // }).filter((d) => {
				// 	return Object.keys(this.filters).every((f) => {
				// 		return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
				// 	});
				// });
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
					});
				});
			},
      readonly_check() {
         return sessionStorage.getItem('readonly_st') === 'true';
      },
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      if (!sessionStorage.hasOwnProperty('user_org') || !sessionStorage.hasOwnProperty('user_dt')) {
        // alert('Аж ахуйн нэгж / Тайлант үе сонгогдоогүй байна.');
        this.loadTable = false;
        return false;
      }
      this.getOptions()
      this.getCustInfo()
      this.getOrgInfo()
      this.initialize()
    },

    methods: {
      initialize () {
        // let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        // let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        // const params = {
        //   org_id: { toJSON: () => user_org.id },
        //   s_dt: user_dt[0],
        //   f_dt: user_dt[1]
        // };
        // axios.get('journal/statement', { params })
        //   .then(response => {
        //     this.gridRows = response.data;
        //     this.loadTable = false;
        //     // console.log(JSON.stringify(this.gridRows));
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
      },

      customFilter(items, search, filter) {
        search = search.toString().toLowerCase()
        return items.filter(row => filter(row["acct_no"], search));
      },

      required(value) {
        if (value instanceof Array && value.length == 0) {
          return 'Заавал утга оруулна уу.';
        }
        return !!value || 'Заавал утга оруулна уу.';
      },

      formatPrice(value) {
        if(!value) return;
        let val = value //(value/1).toFixed(2).replace('. ', ',')
        return Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      custName(value) {
        return this.custs.find(x => x.id === value).name;
      },

      totalBalance(item) {
        // =IF(B$4<300000,J7+G10-H10,IF(700000>B$4,J7+H10-G10,J7+G10-H10))
        return item.debit_amnt > item.credit_amnt ? (item.debit_amnt-item.credit_amnt) : '-'
      },

      checkSelected (data) {
        alert(JSON.stringify(data))
        let item = data.item
        let value = data.value
        if (item.txn_type === '102002') {
          this.selected = value ? this.selected.concat(this.gridRows.filter(x => x.invoice_no === item.invoice_no)) : this.selected.filter(x => x.invoice_no !== item.invoice_no)
          // alert(this.selected)
        }
      },

      editItem (item) {
        this.editedIndex = this.gridRows.indexOf(item)
        this.editedItem = Object.assign({}, item)
        let dAcct = this.accts.find( i => i.notes == this.selectNo );
        if (typeof dAcct !== 'undefined') {
          this.editedItem.debit_acct_no = { notes: dAcct.notes, comb_name: dAcct.comb_name }
        }
        let cAcct = this.accts.find( i => i.notes == this.editedItem.rel_acct_no );
        if (typeof cAcct !== 'undefined') {
          this.editedItem.credit_acct_no = { notes: cAcct.notes, comb_name: cAcct.comb_name }
        }
        let txnTy = this.txntys.find( i => i.code == this.editedItem.txn_type );
        this.editedItem.txn_type = { code: txnTy.code, name: txnTy.name }
        this.editedItem.cust_id = { id: this.editedItem.cust_id, name: this.editedItem.cust_name }
        this.dialog = true
      },

      deleteItem (item) {
        if (this.selected.length == 0) {
          alert('Устгах мэдээллийг сонгоно уу.')
          return
        }
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        let ids = [];
        this.selected.forEach(function (e) {
            ids.push(e.id);
        });

        axios.delete('journal/' + ids)
          .then(response => {
            // sCount ++;
            alert('Сонгогдсон мэдээллийг амжилттай устгалаа.');
            this.reloadMain()
            this.closeDelete()
            // console.log(JSON.stringify(response.data));
          })
          .catch(error => {
            alert('Сонгогдсон мэдээллийг устгахад алдаа гарлаа.');
            console.log(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (!this.$refs.form.validate()) {
          return;
        }
        if (!this.editedItem.debit_amnt && !this.editedItem.credit_amnt) {
          alert('Дебит дүн / Кредит дүн аль нэг нь заавал утгатай байна.')
          return;
        }
        this.editedItem.org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
        this.editedItem.debit_acct_no = this.editedItem.debit_acct_no.notes;
        this.editedItem.credit_acct_no = this.editedItem.credit_acct_no.notes;
        this.editedItem.txn_type = this.editedItem.txn_type.code;
        this.editedItem.cust_name = this.editedItem.cust_id ? this.editedItem.cust_id.name : null;
        this.editedItem.cust_id = this.editedItem.cust_id ? this.editedItem.cust_id.id: null;
        if (this.editedIndex > -1) {
          axios.put('journal/' + this.gridRows[this.editedIndex].id, this.editedItem)
            .then(response => {
              alert('Мэдээлэл амжилттай засварлалаа.');
              this.reloadMain()
            })
            .catch(error => {
              alert('Алдаа гарлаа.');
              console.log(error)
            })
        } else {
          let tableRows = [];
          tableRows.push(this.editedItem)
          axios.post('journal/store', tableRows)
            .then(response => {
              alert('Мэдээлэл амжилттай орууллаа.');
              this.reloadMain()
            })
            .catch(error => {
              alert('Алдаа гарлаа.');
              console.log(error)
            })
        }
        this.close()
      },
      
      reloadMain() {
        this.changeAcct();
      },

      getCustInfo () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1]
        };
        axios.get('customer/org_id', { params })
          .then(response => {
            this.custs = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      getOptions () {
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let year = user_dt[0].substr(0, 4)
        axios.get('commoncode/accts_org_id_year/' + user_org.id + '/' + year)
          .then(response => {
            this.accts = response.data;
          })
          .catch(error => {
            console.log(error)
          })

        axios.get('commoncode/parent_code/102')
          .then(response => {
            this.txntys = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      },

      columnValueList(val) {
				// return this.gridRows.map((d) => d[val]);
        return this.gridRows.map((d) => d[val] ? d[val] : '-');
			},

      changeAcct() {
        if (!this.select) { return }
        let custId = this.selectCust
        let acct = this.select ? this.select : ''
        this.selectNo = this.select ? this.select.notes : ''
        this.first_amnt = this.selectCust ? this.custs.filter(x=> x.id==custId)[0].amnt : Number(acct.notes)>300000 && Number(acct.notes)<700000 ? Number(acct.credit_amnt)-Number(acct.debit_amnt) : Number(acct.debit_amnt)-Number(acct.credit_amnt)

        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let acct_no = this.selectNo
        this.selected = []
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1],
          acct_no: acct_no,
          cust_id: custId
        };
        axios.get('journal/statement', { params })
          .then(response => {
            this.gridRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          })
      },

      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => a + (b[key] || 0), 0)
      },
      
      // wholePrint: function(mCon) {
      //   window.print();
      // },

      wholePrint() {
        if (!this.select) { return alert('Дансны мэдээлэл сонгогдоогүй байна.'); }
        if (this.filteredGridRows.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var org = this.orgs.filter(x=> x.id==org_id)[0]
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        var a_name = this.select ? this.select.comb_name : ''
        var c_name = this.selectCust ? this.custs.filter(x=> x.id==this.selectCust)[0].name : ''

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // // text is placed using x, y coordinates (portrait)
        // doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 0.5, 0.5);
        // doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 6.0, 0.5);
        // doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Аж ахуйн нэгжийн нэр)', 0.5, 0.6);
        // doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Тайлант үе)', 6.0, 0.6);
        // // create a line under heading 
        // doc.setLineWidth(0.001).line(0.5, 0.7, 7.6, 0.7);
        // // set selected account number and customer name
        // doc.setFont("Arial-Mon", "italic").setFontSize(10).text('Данс : ' + a_name, 0.5, 1.0);
        // doc.setFont("Arial-Mon", "italic").setFontSize(10).text('Харилцагч : ' + c_name, 5.5, 1.0);

        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 0.5, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 8.0, 0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Аж ахуйн нэгжийн нэр)', 0.5, 0.6);
        doc.setFont("Arial-Mon", "normal").setFontSize(6).text('(Тайлант үе)', 8.0, 0.6);
        // create a line under heading 
        doc.setLineWidth(0.001).line(0.5, 0.7, 11.0, 0.7);
        // set selected account number and customer name
        doc.setFont("Arial-Mon", "italic").setFontSize(10).text('Данс : ' + a_name, 0.5, 1.0);
        doc.setFont("Arial-Mon", "italic").setFontSize(10).text('Харилцагч : ' + c_name, 8.0, 1.0);
        
        // set headers and data for autoTable
        const headers2 = [
          ['Баримтын дугаар', 'Огноо', 'Гүйлгээний утга', 'Харилцагч', 'Харьцсан данс', 'Дебит мөнгөн дүн', 'Кредит мөнгөн дүн', 'Эцсийн дүн']
        ];
        let data2 = [];
        var fval = [];
        fval.push(null);
        fval.push(null);
        fval.push('эхний үлдэгдэл');
        fval.push(null);
        fval.push(null);
        fval.push(null);
        fval.push(null);
        fval.push(common.moneyFormat(this.first_amnt));
        data2.push(fval);
        this.filteredGridRows.forEach(function (e) {
          var ids = [];
          ids.push(e.invoice_no);
          ids.push(e.invoice_dt);
          ids.push(e.txn_value);
          ids.push(e.cust_name);
          ids.push(e.rel_acct_no);
          ids.push(common.moneyFormat(e.debit_amnt));
          ids.push(common.moneyFormat(e.credit_amnt));
          ids.push(common.moneyFormat(e.last_amnt));
          data2.push(ids);
        });
        var fval2 = [];
        fval2.push(null);
        fval2.push(null);
        fval2.push('нийт дүн');
        fval2.push(null);
        fval2.push(null);
        fval2.push(common.moneyFormat(this.sumField('debit_amnt')));
        fval2.push(common.moneyFormat(this.sumField('credit_amnt')));
        fval2.push(null);
        data2.push(fval2);

        if (data2.length == 1) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, body: data2, margin: { left: 0.5, top: 1.1 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 9} })

        // create footer with page number
        // const pageCount = doc.internal.getNumberOfPages();
        // for(var i = 1; i <= pageCount; i++) {
        //     doc.setFontSize(9).text(String(i), doc.internal.pageSize.width - 0.5, doc.internal.pageSize.height - 0.5);
        // }
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 4.5, finalY+0.5);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 4.5, finalY+0.8);
        
        // saving file
        doc.save(`statement.pdf`);

      },

      printItem(item) {
        if (!item) { return alert('Мэдээлэл сонгогдоогүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var c_name = item.cust_id ? this.custs.filter(x=> x.id==item.cust_id)[0].name : ''
        // var nm = this.selectNo == '100101' ? 'БЭЛЭН МӨНГӨНИЙ ' : 'ХАРИЛЦАХЫН '
        var nm = this.selectNo < 101000 ? 'БЭЛЭН МӨНГӨНИЙ ' : 'ХАРИЛЦАХЫН '
        var title = item.debit_amnt ? nm + 'ОРЛОГЫН БАРИМТ' : nm + 'ЗАРЛАГЫН БАРИМТ'
        var m_title = item.debit_amnt ? 'Тушаасан мөнгөн дүн:  ' : 'Хүлээн авсан мөнгөн дүн:  '
        var amnt = item.debit_amnt ? item.debit_amnt : item.credit_amnt
        var org = this.orgs.filter(x=> x.id==org_id)[0]

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');
        
        // text is placed using x, y coordinates (portrait)
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('НХМаягт МХ', 10, 10);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('Сангийн сайдын 2017 оны 12', 120, 10);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('дугаар сарын 05-ны өдрийн', 120, 17);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('347 тоот тушаалын хавсралт', 120, 24);
        doc.setFont("Arial-Mon", "bold").setFontSize(10).text(title, 30, 40);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Дугаар:  ' + item.invoice_no, 10, 70);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('огноо: ' + item.invoice_dt, 140, 70);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Байгууллагын нэр:   ' + org_nm, 10, 90);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Харилцагч:   ' + c_name, 10, 100);
        var txnVal = doc.splitTextToSize('Гүйлгээний утга: ' + item.txn_value, 200);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(txnVal, 10, 120);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(m_title + common.moneyFormat(amnt), 10, 140);
        var wrds = doc.splitTextToSize(common.inWords(common.moneyFormat2(amnt)), 200);
        doc.setFont("Arial-Mon", "italic").setFontSize(9).text(wrds, 15, 152);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('/үсгээр/', 10, 180);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Хавсралт баримт бичиг', 10, 200);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Захирал: .......................... / ' + org.ceo_firstname + ' /', 50, 240);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Нягтлан бодогч: ............... / ' + org.acct_firstname + ' /', 50, 250);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Тэмдэг', 10, 260);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Тушаасан: ........................ /                      /', 50, 260);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Мөнгө хүлээн авсан: ..............', 50, 270);

        // duplicate another one
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('НХМаягт МХ', 240, 10);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('Сангийн сайдын 2017 оны 12', 350, 10);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('дугаар сарын 05-ны өдрийн', 350, 17);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('347 тоот тушаалын хавсралт', 350, 24);
        doc.setFont("Arial-Mon", "bold").setFontSize(10).text(title, 260, 40);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Дугаар:  ' + item.invoice_no, 240, 70);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('огноо: ' + item.invoice_dt, 370, 70);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Байгууллагын нэр:   ' + org_nm, 240, 90);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Харилцагч:   ' + c_name, 240, 100);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(txnVal, 240, 120);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(m_title + common.moneyFormat(amnt), 240, 140);
        doc.setFont("Arial-Mon", "italic").setFontSize(9).text(wrds, 245, 152);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('/үсгээр/', 240, 180);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Хавсралт баримт бичиг', 240, 200);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Захирал: .......................... / ' + org.ceo_firstname + ' /', 280, 240);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Нягтлан бодогч: ............... / ' + org.acct_firstname + ' /', 280, 250);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Тэмдэг', 240, 260);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Тушаасан: ........................ /                      /', 280, 260);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text('Мөнгө хүлээн авсан: ..............', 280, 270);

        // saving file
        doc.save(`invoice.pdf`);
      },

      checkTxnType (item) {
        return item.txn_type === '102002' && item.notes !== '0' ? false : true;
      },

    },
  }
</script>